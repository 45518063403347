import React, {Component} from 'react';
import {
  SimpleForm,
  minLength,
  maxLength,
  TextInput,
  required,
  SaveButton as BaseSaveButton,
} from 'react-admin';
import {connect} from 'react-redux';
import {changePassword} from '../store/action';
import {Card, CardContent} from '@material-ui/core';
import {getToken} from '../../../utils/auth';
import {withRouter} from 'react-router-dom';
import Title from '../../Common/titleWithRedirect';
import compose from 'recompose/compose';

const validatePassword = [required(), minLength(8), maxLength(64)];

const validateChangePassword = ({newPassword, newPassword2}, {translate}) => {
  const errors = {};

  if (newPassword !== newPassword2) {
    errors.newPassword = [translate('custom.components.passwords.mustMatch')];
    errors.newPassword2 = [translate('custom.components.passwords.mustMatch')];
  }

  return errors;
};

const SaveButton = ({loading, ...props}) => (
  <BaseSaveButton
    {...props}
    redirect={true}
    saving={!loading ? false : {redirect: loading}}
    label="custom.components.passwords.submit"
  />
);

class Change extends Component {
  savePassword = ({newPassword, lastPassword}) =>
    this.props.changePassword({
      newPassword,
      lastPassword,
      token: this.props.match.params.token,
    });

  render() {
    const {
      match: {path},
      loading,
    } = this.props;

    return (
      <Card style={!getToken() ? {marginTop: '3rem'} : {}}>
        <CardContent>
          <Title
            title="custom.components.passwords.change"
            redirect={path === '/change-password' ? '/profile' : '/'}
          />
        </CardContent>
        <SimpleForm
          save={this.savePassword}
          validate={validateChangePassword}
          toolbar={<SaveButton loading={loading} />}>
          {getToken() && (
            <TextInput
              source="lastPassword"
              label="custom.components.passwords.lastPassword"
              validate={validatePassword}
              type="password"
              fullWidth
            />
          )}
          <TextInput
            source="newPassword"
            label="custom.components.passwords.newPassword"
            validate={validatePassword}
            type="password"
            fullWidth
          />
          <TextInput
            source="newPassword2"
            label="custom.components.passwords.newPassword2"
            validate={validatePassword}
            type="password"
            fullWidth
          />
        </SimpleForm>
      </Card>
    );
  }
}

const mapStateToProps = ({passwordReducer}) => ({
  loading: passwordReducer.loading,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {changePassword}),
)(Change);
