import React, {Fragment} from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {translate} from 'react-admin';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import '../../assets/homepage.css';
import {hasRoles} from '../../utils/auth';

const styles = () => ({
  blockInfo: {
    backgroundColor: '#363636',
    color: 'white',
    textAlign: 'center',
    borderRadius: 5 + 'px',
    padding: 0.3 + 'em',
    height: 100 + '%',
  },
  card: {
    padding: 0,
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Arial,sans-serif',
  },
  cardBody: {
    padding: 2 + 'rem',
    '& a': {
      fontStyle: 'italic',
    },
  },
  cardHeader: {
    padding: '0.5em 1em 0.5em 2em',
    backgroundColor: '#ededed',
  },
  caption: {
    fontSize: 1 + 'em',
    marginBottom: 5 + 'px',
  },
  hrMargin: {
    margin: 2 + 'em',
  },
  itemInfos: {
    width: 10 + 'em',
  },
  numberInfo: {
    fontSize: 4 + 'em',
  },
  basics: {
    margin: 2 + 'em',
    textAlign: 'center',
  },
  pemsu: {
    marginTop: 1.5 + 'em',
    textAlign: 'justify',
    maxWidth: 80 + '%',
    '& > div > div': {
      padding: '1.5em 4em',
    },
    '& h1': {
      fontSize: 1.3 + 'em',
      letterSpacing: 0.5,
      fontWeight: 600,
    },
    '& p': {
      textIndent: 5 + 'em',
    },
  },
  securityCode: {
    fontWeight: 700,
    letterSpacing: 3 + 'px',
    border: 1 + 'px solid grey',
    color: '#EF4548',
    padding: 0.2 + 'em',
    borderRadius: 5 + 'px',
    backgroundColor: 'white',
    textAlign: 'center',
  },
  welcome: {
    alignSelf: 'center',
  },
});

const ResumeGridItem = withStyles(styles)(
  ({
    classes: {itemInfos, blockInfo, numberInfo, caption},
    item: {count, label},
  }) => (
    <Grid item className={itemInfos}>
      <Grid container direction="column" className={blockInfo}>
        <Grid item className={numberInfo}>
          {count}
        </Grid>
        <Grid item>
          <Typography className={caption}>{label}</Typography>
        </Grid>
      </Grid>
    </Grid>
  ),
);

const mapStateToProps = ({profileReducer}) => ({
  profile: profileReducer.profile,
  homeInfos: profileReducer.homeInfos,
});

const Resume = compose(
  translate,
  connect(mapStateToProps, null),
)(
  ({translate, homeInfos}) =>
    homeInfos &&
    [
      'machines',
      'programsApproved',
      'machinesToBeEnabled',
      'structureWithMachines',
      'usersCount',
    ].map(info => (
      <ResumeGridItem
        key={info}
        item={{
          count: homeInfos[info],
          label: translate(`custom.components.home.resume.${info}`, {
            smart_count: homeInfos[info],
          }),
        }}
      />
    )),
);

const Basics = compose(
  translate,
  withStyles(styles),
)(({classes, translate}) => (
  <Fragment>
    <Grid item xs={12} className={classes.basics}>
      <Typography paragraph>
        {translate('custom.components.home.basics.firstBloc.whatIsThis')}
      </Typography>
      <Typography paragraph>
        {translate('custom.components.home.basics.firstBloc.userCan')}
      </Typography>
      <Typography paragraph>
        {translate('custom.components.home.basics.firstBloc.managePrgMach')}
        <br />
        {translate('custom.components.home.basics.firstBloc.organise')}
        <br />
        {translate('custom.components.home.basics.firstBloc.manageUsers')}
        <br />
        {translate('custom.components.home.basics.firstBloc.see')}
        <br />
        {translate('custom.components.home.basics.firstBloc.alerts')}
        <br />
        {translate('custom.components.home.basics.firstBloc.extractDatas')}
      </Typography>
      <Typography>
        {translate('custom.components.home.basics.firstBloc.safety')}
      </Typography>
    </Grid>
    <Grid item className={classes.pemsu}>
      <Grid container>
        {['programs', 'structures', 'tags', 'users', 'machines'].map(
          resource => (
            <Grid key={resource} item xs={12} md={6}>
              <Typography component="h1" paragraph className={resource}>
                {translate(`custom.components.menu.${resource}`)}
              </Typography>
              <Typography>
                {translate(
                  `custom.components.home.basics.secondBloc.${resource}`,
                )}
              </Typography>
            </Grid>
          ),
        )}
      </Grid>
    </Grid>
  </Fragment>
));

const list = [
  {
    classname: 'resume',
    title: 'custom.components.home.resume.title',
    content: <Resume />,
    spacing: 16,
  },
  {
    classname: 'basics',
    title: 'custom.components.home.basics.title',
    content: <Basics />,
  },
];

const homeList = hasRoles(['ROLE_SUPERADMIN', 'ROLE_EUROFOURS_TECHNICIAN'])
  ? list.filter(e => e.classname !== 'resume')
  : list;

const PartComponent = compose(
  translate,
  withStyles(styles),
)(({item, translate}) => (
  <Fragment>
    <h1 className={item.classname} style={{margin: '0 0 0.35em 0'}}>
      <span>{translate(item.title)}</span>
    </h1>
    <Grid container justify="center" spacing={item.spacing}>
      {item.content}
    </Grid>
  </Fragment>
));

export default compose(
  connect(mapStateToProps, null),
  translate,
  withStyles(styles),
)(({classes, profile, translate}) => {
  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <span className={classes.welcome}>
                {translate('custom.components.home.welcome')}{' '}
                {profile && `${profile.firstName} ${profile.lastName}`}
              </span>
            </Grid>
            {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_USER']) && (
              <Grid item>
                <Typography align="center">
                  {translate('custom.components.home.securityCode')}
                </Typography>
                <div className={classes.securityCode}>
                  {profile && profile.activationCode}
                </div>
              </Grid>
            )}
          </Grid>
        }
        className={classes.cardHeader}
      />
      <CardContent className={classes.cardBody}>
        {homeList.map((item, index) => (
          <Fragment key={index}>
            <PartComponent item={item} />
            {index < homeList.length - 1 && <hr className={classes.hrMargin} />}
          </Fragment>
        ))}
      </CardContent>
    </Card>
  );
});
