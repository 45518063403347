import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {CardContent, Grid} from '@material-ui/core';
import {updateUserRoles} from '../User/store/action';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import SwitchRights from './switchRoles';
import {translate} from 'react-admin';

const styles = () => ({
  root: {
    paddingBottom: 1 + 'em',
  },
});

export const permissions = [
  {
    name: 'custom.components.menu.programs',
    description: 'custom.components.roles.programs.main',
    role: 'ROLE_PROGRAM_ACCESS',
    subs: [
      {
        name: 'custom.components.roles.create',
        description: 'custom.components.roles.programs.create',
        role: 'ROLE_PROGRAM_CREATE',
        parents: ['ROLE_PROGRAM_ACCESS'],
      },
      {
        name: 'custom.components.roles.edit',
        description: 'custom.components.roles.programs.edit',
        role: 'ROLE_PROGRAM_EDITOR',
        parents: ['ROLE_PROGRAM_ACCESS'],
      },
      {
        name: 'custom.components.roles.delete',
        description: 'custom.components.roles.programs.delete',
        role: 'ROLE_PROGRAM_DELETE',
        parents: ['ROLE_PROGRAM_ACCESS'],
      },
      {
        name: 'custom.components.roles.importExport',
        description: 'custom.components.roles.programs.importExport',
        role: 'ROLE_PROGRAM_IMPORT_EXPORT',
        parents: ['ROLE_PROGRAM_ACCESS'],
      },
      {
        name: 'custom.components.roles.programs.accessUnapproved.title',
        description:
          'custom.components.roles.programs.accessUnapproved.description',
        role: 'ROLE_PROGRAM_APPROVE_ACCESS',
        parents: ['ROLE_PROGRAM_ACCESS'],
        subs: [
          {
            name: 'custom.components.roles.programs.approve.title',
            description: 'custom.components.roles.programs.approve.description',
            role: 'ROLE_PROGRAM_APPROVE_ACTION',
            parents: ['ROLE_PROGRAM_ACCESS', 'ROLE_PROGRAM_APPROVE_ACCESS'],
          },
        ],
      },
    ],
  },
  {
    name: 'custom.components.menu.machines',
    description: 'custom.components.roles.machines.main',
    role: 'ROLE_MACHINE_ACCESS',
    subs: [
      {
        name: 'custom.components.roles.create',
        description: 'custom.components.roles.machines.create',
        role: 'ROLE_MACHINE_CREATE',
        parents: ['ROLE_MACHINE_ACCESS'],
      },
      {
        name: 'custom.components.roles.edit',
        description: 'custom.components.roles.machines.edit',
        role: 'ROLE_MACHINE_EDITOR',
        parents: ['ROLE_MACHINE_ACCESS'],
      },
      {
        name: 'custom.components.roles.delete',
        description: 'custom.components.roles.machines.delete',
        role: 'ROLE_MACHINE_DELETE',
        parents: ['ROLE_MACHINE_ACCESS'],
      },
      {
        name: 'custom.components.roles.machines.accessWithoutStructure.title',
        description:
          'custom.components.roles.machines.accessWithoutStructure.description',
        role: 'ROLE_MACHINE_ACCESS_NO_STRUCTURE',
        parents: ['ROLE_MACHINE_ACCESS'],
      },
      {
        name: 'custom.components.roles.machines.installUninstall.title',
        description:
          'custom.components.roles.machines.installUninstall.description',
        role: 'ROLE_MACHINE_INSTALL_PROGRAM',
        parents: ['ROLE_MACHINE_ACCESS'],
      },
      {
        name: 'custom.components.roles.machines.approve.title',
        description: 'custom.components.roles.machines.approve.description',
        role: 'ROLE_MACHINE_APPROVE_ACTION',
        parents: ['ROLE_MACHINE_ACCESS'],
      },
    ],
  },
  {
    name: 'custom.components.menu.tags',
    description: 'custom.components.roles.tags.main',
    role: 'ROLE_TAG_ACCESS',
    subs: [
      {
        name: 'custom.components.roles.create',
        description: 'custom.components.roles.tags.create',
        role: 'ROLE_TAG_CREATE',
        parents: ['ROLE_TAG_ACCESS'],
      },
      {
        name: 'custom.components.roles.edit',
        description: 'custom.components.roles.tags.edit',
        role: 'ROLE_TAG_EDITOR',
        parents: ['ROLE_TAG_ACCESS'],
      },
      {
        name: 'custom.components.roles.delete',
        description: 'custom.components.roles.tags.delete',
        role: 'ROLE_TAG_DELETE',
        parents: ['ROLE_TAG_ACCESS'],
      },
    ],
  },
  {
    name: 'custom.components.menu.structures',
    description: 'custom.components.roles.structures.main',
    role: 'ROLE_STRUCTURE_ACCESS',
    subs: [
      {
        name: 'custom.components.roles.create',
        description: 'custom.components.roles.structures.create',
        role: 'ROLE_STRUCTURE_CREATE',
        parents: ['ROLE_STRUCTURE_ACCESS'],
      },
      {
        name: 'custom.components.roles.edit',
        description: 'custom.components.roles.structures.edit',
        role: 'ROLE_STRUCTURE_EDITOR',
        parents: ['ROLE_STRUCTURE_ACCESS'],
      },
      {
        name: 'custom.components.roles.delete',
        description: 'custom.components.roles.structures.delete',
        role: 'ROLE_STRUCTURE_DELETE',
        parents: ['ROLE_STRUCTURE_ACCESS'],
      },
      {
        name: 'custom.components.roles.structures.accessAllStructure.title',
        description:
          'custom.components.roles.structures.accessAllStructure.description',
        role: 'ROLE_STRUCTURE_TOTAL_ACCESS',
        parents: ['ROLE_STRUCTURE_ACCESS'],
      },
      {
        name: 'custom.components.roles.structures.addRemove.title',
        description: 'custom.components.roles.structures.addRemove.description',
        role: 'ROLE_STRUCTURE_ADD_MACHINE',
        parents: ['ROLE_STRUCTURE_ACCESS'],
      },
    ],
  },
];

class ManageRights extends Component {
  state = {
    user: this.props.user,
    roles: this.props.user.roles,
  };

  handleRoles = ({role, type}) => {
    let roles = this.state.roles;
    if (type) {
      if (!roles.includes(role)) {
        roles.push(role);
      }
    } else {
      let index = roles.indexOf(role);
      if (-1 !== index) {
        roles.splice(index, 1);
      }
    }
    this.setState({roles});
  };

  render() {
    const {classes, updateUserRoles, translate} = this.props;
    const {roles, user} = this.state;
    try {
      return (
        <CardContent>
          {
            <Grid container>
              {permissions.map((permission, index) => (
                <Grid
                  key={`GridItem-${index}`}
                  item
                  xs={12}
                  md={6}
                  className={classes.root}>
                  <SwitchRights
                    user={user}
                    handleRoles={this.handleRoles}
                    value={permission}
                    roles={roles}
                    updateUserRoles={updateUserRoles}
                  />
                  <ul>
                    {permission.subs &&
                      permission.subs.map((sub, index) => (
                        <Fragment key={`SwitchRights-${index}`}>
                          <SwitchRights
                            user={user}
                            handleRoles={this.handleRoles}
                            value={sub}
                            roles={roles}
                            updateUserRoles={updateUserRoles}
                          />
                          <ul>
                            {sub.subs &&
                              sub.subs.map((s, index) => (
                                <SwitchRights
                                  key={`SwitchRightsSubs-${index}`}
                                  handleRoles={this.handleRoles}
                                  user={user}
                                  value={s}
                                  roles={roles}
                                  updateUserRoles={updateUserRoles}
                                />
                              ))}
                          </ul>
                        </Fragment>
                      ))}
                  </ul>
                </Grid>
              ))}
            </Grid>
          }
          <p>(1) {translate('custom.components.roles.machines.create_help')}</p>
        </CardContent>
      );
    } catch (e) {
      console.log(e);
    }
  }
}

const mapStateToProps = state => ({users: state.userReducer.users});

export default compose(
  connect(mapStateToProps, dispatch => ({
    updateUserRoles: (...args) => dispatch(updateUserRoles(...args)),
  })),
  withStyles(styles),
  translate,
)(ManageRights);
