import {EXPORT_ONE_PROGRAM_SUCCESS} from './action';
import {downloadJSON} from '../downloadJSON';

const initialState = {};

export default (state = initialState, {type, payload}) => {
  if (type === EXPORT_ONE_PROGRAM_SUCCESS) {
    downloadJSON(payload, payload.name || 'program');
  }

  return state;
};
