import React, {Component, Fragment} from 'react';
import {TextInput, ReferenceInput, SimpleForm, required} from 'react-admin';
import StructureInput from '../Input/StructureInput';
import Title from '../Common/Header';
import {Create} from '../Common/Admin';
import {hasRoles} from '../../utils/auth';

const isRequired = [required()];

class StructureCreate extends Component {
  render() {
    //delete tech role
    if (!hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_STRUCTURE_CREATE'])) {
      window.location.replace('/#/not-granted');
    }
    return (
      <Create
        actions={<Fragment />}
        title={
          <Title
            title="custom.components.structures.create.title"
            redirect="list"
            {...this.props}
          />
        }
        {...this.props}>
        <SimpleForm redirect="list">
          <TextInput source="name" validate={isRequired} />
          <ReferenceInput
            source="parent"
            reference="structures"
            label="Parent"
            perPage={500}
            sort={{field: 'name', order: 'ASC'}}>
            {/*<SelectInput optionText="name" />*/}
            <StructureInput />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    );
  }
}

export default StructureCreate;
