import {all, put, takeEvery} from 'redux-saga/effects';
import {showNotification as showNotificationAction} from 'react-admin';
import {
  MACHINES_GET_ALL_REQUEST,
  MACHINES_GET_ALL_SUCCESS,
  MACHINE_GET_ONE,
  MACHINE_GET_ONE_SUCCESS,
  MACHINES_ACTIVE_ONE_REQUEST,
  MACHINES_GET_COUNT_REQUEST,
  MACHINES_GET_COUNT_REQUEST_SUCCESS,
  MACHINE_GET_ERRORS,
  MACHINE_GET_ERRORS_SUCCESS,
  MACHINE_GET_ACTIVITIES,
  MACHINE_GET_ACTIVITIES_SUCCESS,
  MACHINE_UPDATE_PROGRAM_POSITION,
  MACHINE_UPDATE_PROGRAM_POSITION_SUCCESS,
  MACHINE_TYPES_GET_ALL_REQUEST,
  MACHINE_TYPES_GET_ALL_SUCCESS,
} from '../components/Machine/store/action';
import {errorMessageHandler} from './helpers';
import {client} from '../utils/api-client';

function* handleMachine(action) {
  const {type, payload} = action;
  switch (type) {
    case MACHINES_GET_ALL_REQUEST: {
      try {
        const response = yield client(`machines${payload}`);
        yield put({
          type: MACHINES_GET_ALL_SUCCESS,
          payload: response['hydra:member'],
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case MACHINE_GET_ONE: {
      try {
        const response = yield client(`machines/${payload}`);
        yield put({
          type: MACHINE_GET_ONE_SUCCESS,
          payload: response,
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case MACHINE_GET_ERRORS: {
      try {
        const response = yield client(`machines/${payload}/errors`);
        yield put({
          type: MACHINE_GET_ERRORS_SUCCESS,
          payload: response['hydra:member'],
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case MACHINE_GET_ACTIVITIES: {
      try {
        const response = yield client(`machines/${payload}/activities`);
        yield put({
          type: MACHINE_GET_ACTIVITIES_SUCCESS,
          payload: response['hydra:member'],
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case MACHINES_GET_COUNT_REQUEST: {
      try {
        const response = yield client('machines');
        yield put({
          type: MACHINES_GET_COUNT_REQUEST_SUCCESS,
          payload: response['hydra:totalItems'],
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    case MACHINES_ACTIVE_ONE_REQUEST: {
      try {
        yield client(payload.id, {data: {active: payload.active}});
      } catch (e) {}
      break;
    }
    case MACHINE_UPDATE_PROGRAM_POSITION: {
      try {
        const response = yield client(payload.id, {
          data: {
            position: parseInt(payload.position, 10),
          },
          method: 'PUT',
        });
        yield put({
          type: MACHINE_UPDATE_PROGRAM_POSITION_SUCCESS,
          payload: response,
        });
      } catch (e) {}
      break;
    }
    case MACHINE_TYPES_GET_ALL_REQUEST: {
      try {
        const response = yield client('categories');
        yield put({
          type: MACHINE_TYPES_GET_ALL_SUCCESS,
          payload: response['hydra:member'],
        });
      } catch (e) {
        yield put(showNotificationAction(errorMessageHandler(e), 'warning'));
      }
      break;
    }
    default:
      break;
  }
}

export default function* watchMachinesActions() {
  yield all([takeEvery(MACHINES_GET_ALL_REQUEST, handleMachine)]);
  yield all([takeEvery(MACHINE_GET_ONE, handleMachine)]);
  yield all([takeEvery(MACHINES_GET_COUNT_REQUEST, handleMachine)]);
  yield all([takeEvery(MACHINE_GET_ERRORS, handleMachine)]);
  yield all([takeEvery(MACHINE_GET_ACTIVITIES, handleMachine)]);
  yield all([takeEvery(MACHINES_ACTIVE_ONE_REQUEST, handleMachine)]);
  yield all([takeEvery(MACHINE_UPDATE_PROGRAM_POSITION, handleMachine)]);
  yield all([takeEvery(MACHINE_TYPES_GET_ALL_REQUEST, handleMachine)]);
}
