import React from 'react';
import {
  Datagrid,
  TextField,
  ReferenceField,
  ChipField,
  SingleFieldList,
  ReferenceArrayField,
  FunctionField,
  DateField,
  BulkDeleteWithConfirmButton,
  translate,
} from 'react-admin';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {List} from '../Common/Admin';
import {NameField} from '../Common/Field';
import ProgramActions from '../ActionsCards/ProgramActions';
import {ProgramFilters} from '../ActionsCards/CustomFilter';
import {withStyles} from '@material-ui/core';
import Title from '../Common/Header';
import {hasRoles} from '../../utils/auth';
import {TopBarList, Breadcrumb} from '../TopBar';
import {AddButton} from '../Buttons';
import CustomMachineField from './Field/CustomMachinesField';

const styles = theme => ({
  cellAlignCenter: {
    textAlign: 'center',
  },
  tagchip: {
    position: 'relative',
    height: '28px',
    margin: '0',
    padding: '0 5px 0 10px',
    boxShadow: 'inset 0 0 0 1px ' + theme.palette.primary.main,
    borderRadius: '12px 4px 4px 12px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
    '&:after': {
      content: "''",
      position: 'absolute',
      margin: 'auto',
      top: 0,
      bottom: 0,
      left: 8,
      float: 'left',
      width: 5,
      height: 5,
      borderRadius: '50%',
      background: '#fff',
      border: '1px solid' + theme.palette.primary.main,
    },
  },
  more: {
    marginTop: `${theme.spacing.unit}px`,
    opacity: 0.7,
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
});

const PostBulkActionButtons = ({selectedIds}) => (
  <>
    {/* default bulk delete action */}
    {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_PROGRAM_DELETE']) && (
      <BulkDeleteWithConfirmButton
        resource="programs"
        selectedIds={selectedIds}
      />
    )}
  </>
);

const ProgramList = ({classes, ...rest}) => {
  const {location, selectedIds, translate} = rest;

  if (!hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_PROGRAM_ACCESS'])) {
    window.location.replace('/#/not-granted');
  }

  return (
    <>
      <TopBarList>
        <Breadcrumb categoryName="custom.components.menu.programs" />
        <div>
          {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_PROGRAM_DELETE']) && (
            <>
              {selectedIds.length ? (
                <BulkDeleteWithConfirmButton
                  resource="programs"
                  selectedIds={selectedIds}
                />
              ) : null}
            </>
          )}
          {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_PROGRAM_CREATE']) && (
            <AddButton
              basePath="/programs"
              roles={['ROLE_CLIENT_ADMIN', 'ROLE_PROGRAM_CREATE']}
            />
          )}
        </div>
      </TopBarList>
      <List
        title={<Title title="custom.components.menu.programs" {...rest} />}
        actions={<ProgramActions location={location} />}
        filters={<ProgramFilters location={location} />}
        sort={{field: 'updatedAt', order: 'DESC'}}
        bulkActionButtons={
          <PostBulkActionButtons
            resource="programs"
            selectedIds={selectedIds}
          />
        }
        perPage={10}
        {...rest}>
        <Datagrid
          rowStyle={record => ({
            background: selectedIds.includes(record.id) && '#fcd0d0',
          })}>
          <NameField source="name" />
          <ReferenceField
            source="category"
            reference="categories"
            linkType={false}>
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" />
          <FunctionField
            source="updatedAt"
            render={record =>
              record.updatedAt === record.createdAt ? (
                '-'
              ) : (
                <DateField record={record} source="updatedAt" />
              )
            }
          />
          <FunctionField
            source="programMachines"
            render={record =>
              Array.isArray(record.programMachines) &&
              record.programMachines.length
            }
          />
          {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_TAG_ACCESS']) ? (
            <ReferenceArrayField reference="tags" source="tags">
              <SingleFieldList
                style={{
                  display: 'flex',
                  gap: '4px',
                  marginTop: '4px',
                  marginBottom: '4px',
                  maxWidth: '250px',
                }}>
                <ChipField source="name" className={classes.tagchip} />
              </SingleFieldList>
            </ReferenceArrayField>
          ) : (
            ''
          )}
          {/* <CustomMachineField
            label={translate('resources.programs.fields.nbMachines')}
            source="programMachines"
          />*/}
        </Datagrid>
      </List>
    </>
  );
};

const mapStateToProps = ({admin}) => ({
  selectedIds: admin.resources.programs.list.selectedIds,
  programs: admin.resources.programs.data,
});

export default compose(
  translate,
  withStyles(styles),
  connect(mapStateToProps, {}),
)(ProgramList);
