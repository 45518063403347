import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {translate} from 'react-admin';
import {userLogout} from 'ra-core';
import {Menu, MenuItem, Button, withStyles} from '@material-ui/core';
import {Dehaze, Settings, PowerSettingsNew} from '@material-ui/icons';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import './menu.css';

import TimelineIcon from '@material-ui/icons/Timeline';

const styles = () => ({
  iconStyle: {
    paddingRight: '0.8em',
  },
});

class Gear extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  render() {
    const {anchorEl} = this.state;
    const {translate, classes} = this.props;
    return (
      <>
        <Button
          aria-owns={anchorEl ? 'navbar-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          style={{minWidth: 0, width: 100 + '%', color: 'white'}}>
          <Dehaze style={{minWidth: 0, padding: 0}} />
        </Button>
        <Menu
          id="navbar-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}>
          <Link to={'/activity'}>
            <MenuItem onClick={this.handleClose}>
              <TimelineIcon className={classes.iconStyle} />
              {translate('custom.components.appbar.activity')}
            </MenuItem>
          </Link>
          <Link to={'/profile'}>
            <MenuItem onClick={this.handleClose}>
              <Settings className={classes.iconStyle} />
              {translate('custom.components.appbar.profile')}
            </MenuItem>
          </Link>
          <MenuItem
            style={{color: 'white', fontWeight: 300}}
            onClick={() => this.props.userLogout('/login')}>
            <PowerSettingsNew className={classes.iconStyle} />
            {translate('custom.auth.disconnect')}
          </MenuItem>
        </Menu>
      </>
    );
  }
}

export default compose(
  translate,
  connect(undefined, {userLogout}),
  withStyles(styles),
)(Gear);
