import React from 'react';
import {Link} from 'react-router-dom';
import {Card, CardContent, CardHeader, withStyles} from '@material-ui/core';
import {Forward} from '@material-ui/icons';
import {translate} from 'react-admin';
import PartialHistory from '../History/Partial';
import PartialAccessPlus from '../History/PartialAccessPlus';

import {compose} from 'recompose';
import {hasRoles} from '../../utils/auth';

const styles = () => ({
  card: {
    padding: '1em',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  forward: {
    transform: 'rotate(180deg)',
  },
});

const History = ({translate, classes}) => {
  return (
    <Card className={classes.card}>
      <Link className={classes.link} to={`/profile`}>
        <Forward className={classes.forward} />
        {translate('custom.components.profile.history.backProfile')}
      </Link>
      <CardHeader
        title={translate('custom.components.profile.history.title')}
      />
      <CardContent>
        <PartialHistory />
      </CardContent>
      {hasRoles(['ROLE_SUPERADMIN']) && (
        <>
          <CardHeader
            title={translate(
              'custom.components.profile.history.accessPlus.title',
            )}
          />
          <CardContent>
            <PartialAccessPlus />
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default compose(translate, withStyles(styles))(History);
