import React, {Component} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {ReferenceArrayField, translate} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Checkbox,
} from '@material-ui/core';
import {compose} from 'recompose';
import {setSelectedStructures} from '../Structure/store/action';

const EnhancedTableHead = translate(
  ({translate, onSelectAllClick, numSelected, rowCount}) => (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        <TableCell>{translate('custom.commons.name')}</TableCell>
      </TableRow>
    </TableHead>
  ),
);

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight: {
    color: theme.palette.text.primary,
    backgroundColor: '#f14343',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

const EnhancedTableToolbar = withStyles(toolbarStyles)(
  translate(
    ({classes, translate, numSelected}) =>
      numSelected > 0 && (
        <Toolbar
          className={classNames(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}>
          <div className={classes.title}>
            <Typography color="inherit" variant="subheading">
              {translate('ra.action.bulk_actions', {
                smart_count: numSelected,
              })}
            </Typography>
          </div>
        </Toolbar>
      ),
  ),
);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class EnhancedTable extends Component {
  constructor(props) {
    super(props);

    const {user, selectedStructures} = props;

    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: selectedStructures[user['@id']] || [],
      data: [],
    };
  }

  setDatas = data => {
    this.setState({data});
  };

  handleSelectAllClick = event => {
    const {user, selectedStructures, setSelectedStructures} = this.props;

    if (event.target.checked) {
      this.setState(state => ({selected: state.data.map(n => n.id)}));
      selectedStructures[user['@id']] = this.state.data.map(n => n.id);
      setSelectedStructures(selectedStructures);
      return;
    }

    this.setState({selected: []});
    selectedStructures[user['@id']] = [];
    setSelectedStructures(selectedStructures);
  };

  handleClick = (event, id) => {
    const {user, selectedStructures, setSelectedStructures} = this.props;
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    selectedStructures[user['@id']] = newSelected;
    setSelectedStructures(selectedStructures);
    this.setState({selected: newSelected});
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const {translate, classes, basePath, user} = this.props;
    const {selected, data} = this.state;

    return (
      <div className={classes.root}>
        <EnhancedTableToolbar numSelected={selected.length} />
        {Array.isArray(user.structures) && user.structures.length ? (
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={this.handleSelectAllClick}
                rowCount={data.length}
              />
              <TableBody>
                <ReferenceArrayField
                  source="structures"
                  record={user}
                  basePath={basePath}
                  reference="structures"
                  resource="users"
                  linkType={false}>
                  <StructureFactory
                    handleClick={this.handleClick}
                    isSelected={this.isSelected}
                    setDatas={this.setDatas}
                  />
                </ReferenceArrayField>
              </TableBody>
            </Table>
          </div>
        ) : (
          <Typography>
            {translate('custom.components.profile.structures.noResults')}
          </Typography>
        )}
      </div>
    );
  }
}

class StructureFactory extends Component {
  componentDidMount() {
    let datas = [];
    Object.entries(this.props.data).map(([key, value]) => datas.push(value));
    this.props.setDatas(datas);
  }

  render() {
    const {data, handleClick} = this.props;
    return Object.entries(data).map(([key, value]) => {
      const isSelected = this.props.isSelected(value['@id']);
      return (
        <TableRow
          hover
          onClick={event => handleClick(event, value['@id'])}
          role="checkbox"
          aria-checked={isSelected}
          tabIndex={-1}
          key={key}
          selected={isSelected}>
          <TableCell padding="checkbox">
            <Checkbox checked={isSelected} />
          </TableCell>
          <TableCell>{value.name}</TableCell>
        </TableRow>
      );
    });
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({structureReducer}) => ({
  selectedStructures: structureReducer.selectedStructures,
});

export default compose(
  translate,
  connect(mapStateToProps, {setSelectedStructures}),
  withStyles(styles),
)(EnhancedTable);
