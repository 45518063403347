import React, {Component, Fragment} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography, Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import {Done, Clear, Info, Warning} from '@material-ui/icons';
import HistoryItemContent from '../History/ItemContent';
import openSocket from 'socket.io-client';
import {getToken} from '../../utils/auth';
import {getAppBarHistory} from '../History/store/action';
import './appbar.css';
import classNames from 'classnames';

const styles = {
  common: {
    fontSize: 12 + 'px',
  },
  error: {
    color: 'red',
  },
  info: {
    color: 'cornflowerblue',
  },
  success: {
    color: 'green',
  },
  warning: {
    color: 'orange',
  },
};

class FadeMenu extends Component {
  constructor(props) {
    super(props);
    this.socket = openSocket(process.env.REACT_APP_SOCKET_ENTRYPOINT);

    this.state = {
      toggled: false,
      hasNext: [],
    };
  }

  componentDidMount() {
    this.socket.emit('clientLogin', getToken());

    this.socket.on('apiSendNotification', data => {
      this.handleShowHistory(data);
    });
  }

  handleShowHistory = props => {
    let {hasNext} = this.state;
    setTimeout(() => {
      hasNext.splice(0, 1);
      if (!this.state.hasNext.length) {
        this.setState({toggled: false});
        this.props.getAppBarHistory(null);
      }
    }, 5000);
    hasNext.push(props);
    this.setState({toggled: true, hasNext});
    this.props.getAppBarHistory(props);
  };

  render() {
    const {appbarHistory} = this.props;
    return (
      <div
        style={{
          width: 100 + '%',
          height: 100 + '%',
          borderLeft: 1 + 'px solid grey',
        }}>
        <span style={{flex: 1}}>
          <Item item={appbarHistory} />
        </span>
      </div>
    );
  }
}

const Item = withStyles(styles)(({classes, item}) => {
  let customClass = [classes.common];
  let customLogo = null;

  if (item) {
    switch (item.typeEvent) {
      case 'Error':
        customClass.push(classes.error);
        customLogo = <Clear />;
        break;
      case 'Add':
      case 'Remove':
        customClass.push(classes.success);
        customLogo = <Done />;
        break;
      case 'Warning':
        customClass.push(classes.warning);
        customLogo = <Warning />;
        break;
      default:
        customClass.push(classes.info);
        customLogo = <Info />;
        break;
    }
  }

  return (
    <Grid
      container
      className={item ? 'slideDown' : 'slideUp'}
      style={{
        alignItems: 'center',
        width: 100 + '%',
      }}>
      {item && (
        <Fragment>
          <Grid item className={classNames(customClass)}>
            {customLogo}
          </Grid>
          <Grid item>
            <Typography className={classNames(customClass)}>
              <HistoryItemContent item={item} />
            </Typography>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
});
const mapStateToProps = ({historyReducer}) => ({
  appbarHistory: historyReducer.appbarHistory,
});

export default connect(mapStateToProps, {getAppBarHistory})(FadeMenu);
