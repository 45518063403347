import React, {Component, cloneElement, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Redirect, Link} from 'react-router-dom';
import {
  SvgIcon,
  Button as BaseButton,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  Add,
  Close,
  StoreMallDirectory,
  LocalOffer,
  AccountCircle,
  Done,
  Block,
} from '@material-ui/icons';
import DeleteAction from '../Common/deleteAction';
import AssignAction from '../Common/assignAction';
import BooleanAction from '../Common/booleanAction';
import {translate, Responsive} from 'react-admin';
import classNames from 'classnames';
import {stringify} from 'query-string';
import {get} from 'lodash';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {hasRoles, getBaseRoles} from '../../utils/auth';
import {importOneProgram} from './store/action';

const styles = theme => ({
  button: {
    padding: 0,
    '& svg': {
      fontSize: '2em',
    },
    '& p': {
      textAlign: 'left',
      textTransform: 'none',
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 5,
      marginRight: 25,
      lineHeight: 1 + 'em',
      '& > span': {
        fontWeight: '500',
        '&:nth-child(2)': {
          fontSize: 0.7 + 'em',
        },
      },
    },
  },
  green: {
    '& svg': {
      color: '#92c200',
    },
  },
  red: {
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
  buttonInactive: {
    color: 'gray !important',
    opacity: 0.3,
  },
});

const Icon = ({target}) => {
  switch (target) {
    case 'programs':
      return (
        <SvgIcon viewBox="-3 -1 21 21">
          <path d="M12.56,0H1.79A1.79,1.79,0,0,0,0,1.79V16.15a1.79,1.79,0,0,0,1.79,1.79H12.56a1.79,1.79,0,0,0,1.79-1.79V1.79A1.79,1.79,0,0,0,12.56,0ZM1.79,1.79H6.28V9L4,7.62,1.79,9Z" />
        </SvgIcon>
      );
    case 'machines':
      return (
        <SvgIcon viewBox="7 -2 21 21">
          <path d="M22.12,0H13a1.17,1.17,0,0,0-1.17,1.17V15.62A1.17,1.17,0,0,0,13,16.79h9.1a1.17,1.17,0,0,0,1.17-1.17V1.17A1.17,1.17,0,0,0,22.12,0ZM18.93,11a1.17,1.17,0,0,1-1.17,1.17H15.4A1.17,1.17,0,0,1,14.23,11V3.56A1.17,1.17,0,0,1,15.4,2.39h2.36a1.17,1.17,0,0,1,1.17,1.17Zm2.83-1.44H20.09V5.38h1.67Z" />
        </SvgIcon>
      );
    case 'tags':
      return <LocalOffer />;
    case 'users':
      return <AccountCircle />;
    case 'structures':
      return <StoreMallDirectory />;
    case 'boolean':
      return (
        <SvgIcon viewBox="0 -4 17 17">
          <rect
            x="0.34"
            y="0.34"
            width="15.77"
            height="7.86"
            rx="3.93"
            ry="3.93"
            transform="translate(16.46 8.54) rotate(-180)"
          />
          <circle style={{color: 'white'}} cx="11.81" cy="4.27" r="3.06" />
        </SvgIcon>
      );
    case 'approve':
      return <Done />;
    case 'disapprove':
      return <Block />;
    default:
      return null;
  }
};

export const Button = withStyles(styles)(
  ({
    to,
    isActive = false,
    color = 'green',
    labelProps,
    classes,
    roles = [],
    ...rest
  }) => (
    <BaseButton
      component={to ? Link : 'span'}
      to={to}
      className={classNames(
        classes.button,
        isActive ? classes[color] : classes.buttonInactive,
      )}
      disabled={!isActive || !(roles && hasRoles([...roles, ...getBaseRoles]))}
      {...rest}>
      <ButtonLabel {...labelProps} />
    </BaseButton>
  ),
);

const ButtonLabel = translate(({icon, label, description, translate}) => (
  <Responsive
    small={icon}
    medium={
      <Fragment>
        {icon}
        <Typography>
          {label && <span>{translate(label)}</span>}
          {description && <span>{translate(description)}</span>}
        </Typography>
      </Fragment>
    }
  />
));

class ButtonWithAction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      redirect: false,
    };
  }

  handleClick = () => {
    this.setState({
      isOpen: true,
    });
  };

  handleExitAction = redirect => {
    this.setState({
      isOpen: false,
      redirect: redirect,
    });
  };

  render() {
    const {isOpen, redirect} = this.state;
    const {buttonProps, action, roles, ...rest} = this.props;

    // Delete Action Specific
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <Fragment>
        <Button
          roles={roles}
          onClick={() => this.handleClick()}
          {...buttonProps}
        />
        {isOpen &&
          cloneElement(action, {
            ...rest,
            onExit: this.handleExitAction,
          })}
      </Fragment>
    );
  }
}

ButtonWithAction.propTypes = {
  buttonProps: PropTypes.object.isRequired,
  action: PropTypes.element.isRequired,
  basePath: PropTypes.string,
  selectedIds: PropTypes.array,
  idsToUpdate: PropTypes.array,
  record: PropTypes.object,
  resource: PropTypes.string,
  resourceToUpdate: PropTypes.string,
};

const AddButton = ({basePath, roles, ...rest}) => (
  <Button
    isActive={true}
    to={basePath && {pathname: `${basePath}/create`}}
    roles={roles}
    labelProps={{
      label: 'custom.buttons.add',
      icon: <Add />,
    }}
    {...rest}
  />
);

const DeleteButton = props => (
  <ButtonWithAction
    buttonProps={{
      isActive: true,
      color: 'red',
      labelProps: {
        label: 'custom.buttons.deletebutton',
        icon: <Close />,
      },
    }}
    action={<DeleteAction />}
    {...props}
  />
);

DeleteButton.propTypes = {
  selectedIds: PropTypes.array,
  record: PropTypes.object,
  basePath: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
};

class ExportButton extends Component {
  render() {
    const {handleClick, selectedIds, ...rest} = this.props;
    return (
      <Button
        isActive={selectedIds.length > 0}
        onClick={handleClick}
        color="red"
        labelProps={{
          label: 'custom.buttons.exportbutton',
          icon: (
            <SvgIcon viewBox="0 -3 24 24">
              <polygon points="10.79 0 7.14 3.65 9.72 3.65 9.72 10.15 11.75 10.15 11.75 3.65 14.44 3.65 10.79 0" />
              <path d="M16.05,13.62H5.54A5.55,5.55,0,0,1,0,8.07v-3H1.85v3a3.7,3.7,0,0,0,3.69,3.7H16.05a3.7,3.7,0,0,0,3.69-3.7v-3h1.85v3A5.55,5.55,0,0,1,16.05,13.62Z" />
            </SvgIcon>
          ),
          description: 'custom.buttons.action.export',
        }}
        {...rest}
      />
    );
  }
}

class ImportButtonComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reader: new FileReader(),
    };
  }

  handleChange = files => {
    const {reader} = this.state;

    reader.readAsText(files[0], 'UTF-8');
    reader.onload = evt => {
      this.props.importOneProgram(JSON.parse(evt.target.result));
    };
    reader.onerror = () => {
      console.error(reader.error);
    };
  };

  sanitizeProps = ({importOneProgram, ...rest}) => rest;

  render() {
    const {isActive = true, selectedIds, ...rest} = this.props;
    const disabled = !isActive || selectedIds.length;

    return (
      <Fragment>
        <input
          hidden
          onChange={e => {
            this.handleChange(e.target.files);
          }}
          type="file"
          id="import-program-button"
          accept=".prog"
          disabled={disabled}
        />
        <label htmlFor="import-program-button">
          <Button
            isActive={!disabled}
            labelProps={{
              label: 'custom.buttons.importbutton',
              icon: (
                <SvgIcon viewBox="0 -3 24 24">
                  <polygon points="10.79 10.15 14.44 6.5 11.86 6.5 11.86 0 9.84 0 9.84 6.5 7.14 6.5 10.79 10.15" />
                  <path d="M16.05,13.62H5.54A5.55,5.55,0,0,1,0,8.07v-3H1.85v3a3.7,3.7,0,0,0,3.69,3.7H16.05a3.7,3.7,0,0,0,3.69-3.7v-3h1.85v3A5.55,5.55,0,0,1,16.05,13.62Z" />
                </SvgIcon>
              ),
              description: 'custom.buttons.action.import',
            }}
            {...this.sanitizeProps(rest)}
          />
        </label>
      </Fragment>
    );
  }
}

const ImportButton = connect(undefined, {importOneProgram})(
  ImportButtonComponent,
);

const AssignButton = translate(
  ({translate, location, target, isActive, single, ...rest}) => {
    const {resource, assign, resourceToUpdate} = rest;
    const action = (assign && 'assign') || 'unassign';
    const selectedIds = rest.selectedIds || [];

    const query = {};
    query[resource] = single ? selectedIds[0] : selectedIds; // si "single", on ne passe pas un tableau mais une valeur unique

    if (single) {
      query[resource.substring(0, resource.length - 1)] = query[resource];
      query[resource] = undefined;
    }
    const tooManyIds = single && selectedIds.length > 1;

    return (
      <ButtonWithAction
        buttonProps={{
          to: location
            ? Object.assign(location, {
                pathname: `/${target}`,
                search:
                  'unassign' === action &&
                  !(
                    'programs' === resource && 'machines' === resourceToUpdate
                  ) &&
                  !('programs' === resourceToUpdate && 'machines' === resource)
                    ? `?${stringify({
                        filter: JSON.stringify(query),
                      })}`
                    : '',
                state: Object.assign(location.state, {
                  title: translate(
                    `custom.title.${action}.${resource}.${target}`,
                    {
                      subject: translate(`custom.title.${resource}`, {
                        smart_count: selectedIds.length,
                      }),
                    },
                  ),
                }),
              })
            : undefined,
          isActive:
            !tooManyIds &&
            isActive &&
            selectedIds.length &&
            ('tags' === resource && assign ? selectedIds.length < 6 : true),
          color: (assign && 'green') || 'red',
          labelProps: {
            label: `custom.buttons.${action}.${resourceToUpdate ||
              resource}.${target}.label`,
            icon: <Icon target={target} />,
            description: `custom.buttons.${action}.${resourceToUpdate ||
              resource}.${target}.description`,
          },
        }}
        action={<AssignAction />}
        {...rest}
      />
    );
  },
);

AssignButton.defaultProps = {
  isActive: true,
  assign: false,
};

const mapStateToProps = (state, props) => {
  const {resource, property, switchOn, selectedIds} = props;
  const resourceState = state.admin.resources[resource];

  return {
    isCorrect: selectedIds
      .map(id => get(resourceState.data, id))
      .filter(r => typeof r !== 'undefined')
      .reduce((prev, next) => get(next, property) === !switchOn && prev, true),
  };
};

const enhance = compose(translate, connect(mapStateToProps, {}));

const BooleanButton = enhance(
  ({translate, isCorrect, isActive, label, icon, ...rest}) => {
    const {resource, switchOn, resourceToUpdate} = rest;
    const selectedIds = rest.selectedIds || [];

    return (
      <ButtonWithAction
        buttonProps={{
          isActive: isActive && selectedIds.length && isCorrect,
          color: (switchOn && 'green') || 'red',
          labelProps: {
            label:
              label ||
              `custom.buttons.action.boolean.${resourceToUpdate || resource}.${
                switchOn ? 'on' : 'off'
              }`,
            icon: icon || <Icon target="approve" />,
            description:
              !label &&
              `custom.buttons.action.classic.${resourceToUpdate || resource}`,
          },
        }}
        action={<BooleanAction />}
        {...rest}
      />
    );
  },
);

BooleanButton.defaultProps = {
  switchOn: false,
  isActive: true,
};

const ApproveButton = enhance(
  ({translate, isCorrect, isActive, label, icon, ...rest}) => {
    const {resource, resourceToUpdate} = rest;
    const selectedIds = rest.selectedIds || [];

    return (
      <ButtonWithAction
        buttonProps={{
          isActive: isActive && selectedIds.length && isCorrect,
          color: 'green',
          labelProps: {
            label:
              label ||
              `custom.buttons.action.boolean.${resourceToUpdate ||
                resource}.on`,
            icon: icon || <Icon target="approve" />,
            description:
              !label &&
              `custom.buttons.action.classic.${resourceToUpdate || resource}`,
          },
        }}
        action={<BooleanAction />}
        {...rest}
      />
    );
  },
);

ApproveButton.defaultProps = {
  switchOn: false,
  isActive: true,
};

const DisApproveButton = enhance(
  ({translate, isCorrect, isActive, label, icon, ...rest}) => {
    const {resource, resourceToUpdate} = rest;
    const selectedIds = rest.selectedIds || [];

    return (
      <ButtonWithAction
        buttonProps={{
          isActive: isActive && selectedIds.length && isCorrect,
          color: 'red',
          labelProps: {
            label:
              label ||
              `custom.buttons.action.boolean.${resourceToUpdate ||
                resource}.off`,
            icon: icon || <Icon target="disapprove" />,
            description:
              !label &&
              `custom.buttons.action.classic.${resourceToUpdate || resource}`,
          },
        }}
        action={<BooleanAction />}
        {...rest}
      />
    );
  },
);

DisApproveButton.defaultProps = {
  switchOn: false,
  isActive: true,
};

export {
  AddButton,
  DeleteButton,
  BooleanButton,
  ImportButton,
  ExportButton,
  AssignButton,
  ApproveButton,
  DisApproveButton,
};
