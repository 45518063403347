import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  getHistory,
  getProfileHistory,
  getAccessPlusHistory,
} from './store/action';
import GridList from '../Common/GridList';
import {Grid, Typography, CircularProgress} from '@material-ui/core';
import HistoryItemContent from './ItemContent';
import {translate} from 'react-admin';
import {compose} from 'recompose';

const HistoryItem = ({item}) => {
  return (
    <Grid container wrap="nowrap">
      <Grid item xs={2}>
        <Typography style={{fontWeight: 600}}>
          {new Date(item.dateEvent.date || item.dateEvent).toLocaleString()}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography style={{color: '#606060', fontWeight: 500}}>
          <HistoryItemContent item={item} />
        </Typography>
      </Grid>
    </Grid>
  );
};

class PartialHistory extends Component {
  componentDidMount() {
    const {id, getHistory, getProfileHistory} = this.props;

    if (!id) {
      getProfileHistory();
      return;
    }

    getHistory({type: id.split('/')[1], id: id.split('/')[2]});
  }

  componentDidUpdate = prevProps => {
    const {id, getHistory, refresh} = this.props;

    if (!id || (id === prevProps.id && !refresh)) {
      return;
    }

    getHistory({type: id.split('/')[1], id: id.split('/')[2]});
  };

  render() {
    const {title, history, historyLoaded} = this.props;

    return !historyLoaded ? (
      <CircularProgress />
    ) : (
      <GridList
        data={history}
        title={title && 'custom.commons.history'}
        noDataDesc="custom.commons.noHistory">
        <HistoryItem />
      </GridList>
    );
  }
}

PartialHistory.propTypes = {
  id: PropTypes.string,
  title: PropTypes.bool,
  history: PropTypes.array.isRequired,
  historyLoaded: PropTypes.bool.isRequired,
};

const mapStatetoProps = ({historyReducer}) => ({
  history: historyReducer.history,
  historyLoaded: historyReducer.historyLoaded,
  refresh: historyReducer.refresh,
});

export default compose(
  translate,
  connect(mapStatetoProps, {
    getHistory,
    getProfileHistory,
    getAccessPlusHistory,
  }),
)(PartialHistory);
