import React, {Component} from 'react';
import {
  Datagrid,
  TextField,
  ReferenceField,
  BooleanField,
  BulkDeleteWithConfirmButton,
  FunctionField,
  translate,
} from 'react-admin';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {List} from '../Common/Admin';
import {NameField} from '../Common/Field';
import {ReportProblem} from '@material-ui/icons';
import Title from '../Common/Header';
import {MachineFilters} from '../ActionsCards/CustomFilter';
import MachineActions from '../ActionsCards/MachineActions';
import {withStyles} from '@material-ui/core';
import {getIdsToUpdate} from '../Common/idsToUpdate';
import {hasRoles} from '../../utils/auth';
import {TopBarList, Breadcrumb} from '../TopBar';

const styles = {
  cellAlignCenter: {
    textAlign: 'center',
  },
};
const PostBulkActionButtons = ({selectedIds}) => (
  <>
    {/* default bulk delete action */}
    {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_MACHINE_DELETE']) && (
      <BulkDeleteWithConfirmButton
        resource="machines"
        selectedIds={selectedIds}
      />
    )}
  </>
);

class MachineList extends Component {
  render() {
    const {classes, translate, ...rest} = this.props;
    const {location, selectedIds} = rest;

    if (
      !hasRoles([
        'ROLE_CLIENT_ADMIN',
        'ROLE_STRUCTURE_EDITOR',
        'ROLE_PROGRAM_EDITOR',
        'ROLE_MACHINE_ACCESS',
      ])
    ) {
      window.location.replace('/#/not-granted');
    }
    return (
      <>
        <TopBarList>
          <Breadcrumb categoryName="custom.components.menu.machines" />
          <div>
            {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_MACHINE_DELETE']) && (
              <>
                {selectedIds.length ? (
                  <BulkDeleteWithConfirmButton
                    resource="machines"
                    selectedIds={selectedIds}
                  />
                ) : null}
              </>
            )}
          </div>
        </TopBarList>
        <List
          title={<Title title="custom.components.menu.machines" {...rest} />}
          actions={<MachineActions location={location} />}
          filters={<MachineFilters location={location} />}
          sort={{field: 'name', order: 'ASC'}}
          filter={getIdsToUpdate(location)}
          bulkActionButtons={
            <PostBulkActionButtons
              resource="machines"
              selectedIds={selectedIds}
            />
          }
          perPage={30}
          {...rest}>
          <Datagrid
            rowStyle={record => {
              return {
                background: selectedIds.includes(record.id) && '#fcd0d0',
              };
            }}>
            <NameField source="name" />
            <TextField source="serialNumber" />
            <ReferenceField
              source="category"
              reference="categories"
              linkType={false}>
              <TextField source="name" />
            </ReferenceField>
            <TextField source="specifications.capacity" />
            {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_STRUCTURE_ACCESS']) ? (
              <ReferenceField
                source="structure"
                reference="structures"
                allowEmpty={true}>
                <TextField source="name" />
              </ReferenceField>
            ) : (
              <></>
            )}
            <FunctionField
              cellClassName={classes.cellAlignCenter}
              label={translate('resources.program_machines.fields.errors')}
              render={record => {
                return Array.isArray(record.errors) &&
                  record.errors.length > 0 ? (
                  <ReportProblem />
                ) : (
                  '-'
                );
              }}
            />
            <BooleanField
              source="active"
              cellClassName={classes.cellAlignCenter}
              headerClassName={classes.cellAlignCenter}
            />
            <BooleanField
              source="approved"
              cellClassName={classes.cellAlignCenter}
              headerClassName={classes.cellAlignCenter}
            />
          </Datagrid>
        </List>
      </>
    );
  }
}

const mapStateToProps = state => ({
  selectedIds: state.admin.resources.machines.list.selectedIds,
});

export default compose(
  translate,
  withStyles(styles),
  connect(mapStateToProps, {}),
)(MachineList);
