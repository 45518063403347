import React, {Component, Fragment} from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  withStyles,
  Button,
} from '@material-ui/core';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {getProfile} from '../Profile/store/action';
import Gear from '../Gear';
import logo from '../../assets/logo_etools.svg';
import {Link} from 'react-router-dom';
import InformationBar from '../InformationBar';
import SelectLanguage from '../SelectLanguage';
import {getToken, hasRoles} from '../../utils/auth';
import {translate} from 'react-admin';

const styles = theme => ({
  appBar: {
    flexGrow: 1,
    zIndex: 1201,
  },
  etoolsLogo: {
    width: '60%',
    padding: '0 1em 0 0.6em',
  },
  informationBar: {
    borderLeft: '2px solid #666',
  },
  selectLanguage: {
    '& > button': {
      padding: 0,
      minWidth: 0,
      width: 100 + '%',
      fontSize: 1.2 + 'em',
      fontWeight: 300,
    },
    backgroundColor: 'inherit',
    borderRight: '2px solid #666',
    borderLeft: '2px solid #666',
    textAlign: 'center',
  },
  profileName: {
    fontSize: 1.2 + 'em',
    fontWeight: 300,
    textAlign: 'center',
    letterSpacing: 0.5,
  },
  role: {
    fontSize: 0.7 + 'em',
    fontWeight: 200,
    textAlign: 'center',
    letterSpacing: 0.5,
  },
  padding: {
    [theme.breakpoints.up('md')]: {
      paddingRight: 24,
    },
  },
  stopImpersonaButton: {
    color: '#f14343',
  },
});

class SimpleAppBar extends Component {
  componentDidMount() {
    if (!getToken() || hasRoles(['ROLE_NEED_2FA'])) {
      return;
    }
    this.props.getProfile();
  }

  handleStopImpersona = () => {
    const token = localStorage.getItem('masterToken');
    const role = localStorage.getItem('masterRole');
    localStorage.setItem('token', token);
    localStorage.setItem('role', role);
    localStorage.removeItem('masterToken');
    localStorage.removeItem('masterRole');
    window.location.replace('/');
  };

  render() {
    const {classes, profile, translate} = this.props;

    const isImpersona = localStorage.getItem('masterToken');

    const renderRole = role => {
      switch (role) {
        case 'ROLE_SUPERADMIN':
          return 'custom.components.appbar.roles.superAdmin';
        case 'ROLE_EUROFOURS_TECHNICIAN':
          return 'custom.components.appbar.roles.eurofoursTechnician';
        case 'ROLE_CLIENT_ADMIN':
          return 'custom.components.appbar.roles.clientAdmin';
        default:
          return 'custom.components.appbar.roles.user';
      }
    };

    return (
      <div className={classes.appBar}>
        <AppBar position="fixed" color="default">
          <Toolbar disableGutters classes={{root: classes.padding}}>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <Link to="/">
                  <img src={logo} alt="eTools" className={classes.etoolsLogo} />
                </Link>
              </Grid>
              <Fragment>
                <Grid
                  item
                  xs={isImpersona ? 4 : 6}
                  className={classes.informationBar}>
                  <InformationBar />
                </Grid>
                {isImpersona && (
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      className={classes.stopImpersonaButton}
                      onClick={this.handleStopImpersona}>
                      {translate('custom.buttons.stopImpersona')}
                    </Button>
                  </Grid>
                )}
                {!!profile && (
                  <Grid item xs={2}>
                    {profile.firstName && profile.lastName && (
                      <Typography className={classes.profileName}>
                        {`${profile.firstName} ${profile.lastName}`}
                      </Typography>
                    )}
                    {profile.roles && (
                      <Typography className={classes.role}>
                        {translate(renderRole(profile && profile.roles[0]))}
                      </Typography>
                    )}
                  </Grid>
                )}
                <Grid item xs={1} className={classes.selectLanguage}>
                  <SelectLanguage />
                </Grid>
                <Grid item xs={1}>
                  <Gear />
                </Grid>
              </Fragment>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = ({profileReducer}) => ({
  profile: profileReducer.profile,
  locale: localStorage.getItem('language'),
});

export default compose(
  connect(mapStateToProps, {getProfile}),
  withStyles(styles),
  translate,
)(SimpleAppBar);
