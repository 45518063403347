import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';

export const HistoryStructureMachine = ({item, translate}) => {
  const {
    typeEvent,
    originId,
    originName,
    targetId,
    targetName,
    actionner,
  } = item;
  const onOrFrom =
    typeEvent === 'Structure add machine' ? 'on structure' : 'from structure';

  return (
    <>
      {translate(`${typeEvent}`)}{' '}
      <Link to={`/machines/%2Fmachines%2F${targetId}/show`}>{targetName}</Link>{' '}
      {translate(onOrFrom)}{' '}
      <Link to={`/structures/%2Fstructures%2F${originId}/show`}>
        {originName}
      </Link>{' '}
      (
      {actionner ? (
        <Link to={`/users/%2Fusers%2F${actionner.id}/show`}>
          {actionner.fullName}
        </Link>
      ) : (
        ''
      )}
      )
    </>
  );
};
export const HistoryStructureUser = ({item, translate}) => {
  const {
    typeEvent,
    originId,
    originName,
    targetId,
    targetName,
    actionner,
  } = item;
  const onOrFrom =
    typeEvent === 'Structure add user' ? 'on structure' : 'from structure';

  return (
    <>
      {translate(`${typeEvent}`)}{' '}
      <Link to={`/users/%2Fusers%2F${targetId}/show`}>{targetName}</Link>{' '}
      {translate(onOrFrom)}{' '}
      <Link to={`/structures/%2Fstructures%2F${originId}/show`}>
        {originName}
      </Link>{' '}
      (
      {actionner ? (
        <Link to={`/users/%2Fusers%2F${actionner.id}/show`}>
          {actionner.fullName}
        </Link>
      ) : (
        ''
      )}
      )
    </>
  );
};
export const HistoryStructure = ({item, translate}) => {
  const {typeEvent, originId, originName, actionner} = item;

  return (
    <>
      {translate(`${typeEvent}`)}{' '}
      <Link to={`/structures/%2Fstructures%2F${originId}/show`}>
        {originName}
      </Link>{' '}
      (
      {actionner ? (
        <Link to={`/users/%2Fusers%2F${actionner.id}/show`}>
          {actionner.fullName}
        </Link>
      ) : (
        ''
      )}
      )
    </>
  );
};
export const HistoryTag = ({item, translate}) => {
  const {typeEvent, originId, originName, actionner} = item;

  return (
    <>
      {translate(`${typeEvent}`)}{' '}
      <Link to={`/tags/%2Ftags%2F${originId}/show`}>{originName}</Link> (
      {actionner ? (
        <Link to={`/users/%2Fusers%2F${actionner.id}/show`}>
          {actionner.fullName}
        </Link>
      ) : (
        ''
      )}
      )
    </>
  );
};
export const HistoryTagProgram = ({item, translate}) => {
  const {
    typeEvent,
    originId,
    originName,
    targetId,
    targetName,
    actionner,
  } = item;
  const onOrFrom =
    typeEvent === 'Tag added on program' ? 'on program' : 'from program';

  return (
    <>
      {translate(`${typeEvent}`)}{' '}
      <Link to={`/tags/%2Ftags%2F${originId}/show`}>{originName}</Link>{' '}
      {translate(onOrFrom)}{' '}
      <Link to={`/programs/%2Fprograms%2F${targetId}/show`}>{targetName}</Link>{' '}
      (
      {actionner ? (
        <Link to={`/users/%2Fusers%2F${actionner.id}/show`}>
          {actionner.fullName}
        </Link>
      ) : (
        ''
      )}
      )
    </>
  );
};
export const HistoryProgramMachine = ({item, translate}) => {
  const {
    typeEvent,
    originId,
    originName,
    targetId,
    targetName,
    actionner,
  } = item;
  const onOrFrom =
    typeEvent === 'Program uninstallation' ? 'from machine' : 'on machine';

  return (
    <>
      {translate(`${typeEvent}`)}{' '}
      <Link to={`/programs/%2Fprograms%2F${originId}/show`}>{originName}</Link>{' '}
      {translate(onOrFrom)}{' '}
      <Link to={`/machines/%2Fmachines%2F${targetId}/show`}>{targetName}</Link>{' '}
      (
      {actionner ? (
        <Link to={`/users/%2Fusers%2F${actionner.id}/show`}>
          {actionner.fullName}
        </Link>
      ) : (
        ''
      )}
      )
    </>
  );
};
export const HistoryProgram = ({item, translate}) => {
  const {typeEvent, originId, originName, actionner} = item;
  return (
    <>
      {translate(`${typeEvent}`)}{' '}
      <Link to={`/programs/%2Fprograms%2F${originId}/show`}>{originName}</Link>{' '}
      (
      {actionner ? (
        <Link to={`/users/%2Fusers%2F${actionner.id}/show`}>
          {actionner.fullName}
        </Link>
      ) : (
        ''
      )}
      )
    </>
  );
};
export const HistoryProgramTag = ({item, translate}) => {
  const {
    typeEvent,
    originId,
    originName,
    targetId,
    targetName,
    actionner,
  } = item;
  const onOrFrom =
    typeEvent === 'Program tag added' ? 'on program' : 'from program';

  return (
    <>
      {translate(`${typeEvent}`)}{' '}
      <Link to={`/tags/%2Ftags%2F${originId}/show`}>{originName}</Link>{' '}
      {translate(onOrFrom)}{' '}
      <Link to={`/programs/%2Fprograms%2F${targetId}/show`}>{targetName}</Link>{' '}
      (
      {actionner ? (
        <Link to={`/users/%2Fusers%2F${actionner.id}/show`}>
          {actionner.fullName}
        </Link>
      ) : (
        ''
      )}
      )
    </>
  );
};
export const HistoryMachine = ({item, translate}) => {
  const {typeEvent, originId, originName, actionner} = item;

  return (
    <>
      {translate(`${typeEvent}`)}{' '}
      <Link to={`/machines/%2Fmachines%2F${originId}/show`}>{originName}</Link>{' '}
      (
      {actionner ? (
        <Link to={`/users/%2Fusers%2F${actionner.id}/show`}>
          {actionner.fullName}
        </Link>
      ) : (
        ''
      )}
      )
    </>
  );
};
export const HistoryUser = ({item, translate}) => {
  const {typeEvent, originId, originName, actionner} = item;
  return (
    <>
      {translate(`${typeEvent}`)}{' '}
      <Link to={`/users/%2Fusers%2F${originId}/show`}>{originName}</Link> (
      {actionner ? (
        <Link to={`/users/%2Fusers%2F${actionner.id}/show`}>
          {actionner.fullName}
        </Link>
      ) : (
        ''
      )}
      )
    </>
  );
};
export const HistoryConnectionAs = ({item, translate}) => {
  const {
    typeEvent,
    originId,
    originName,
    targetId,
    targetName,
    actionner,
  } = item;

  return (
    <>
      <Link to={`/users/%2Fusers%2F${originId}/show`}>{originName}</Link>{' '}
      {translate(`${typeEvent}`)}{' '}
      <Link to={`/users/%2Fusers%2F${targetId}/show`}>{targetName}</Link> (
      {actionner ? (
        <Link to={`/users/%2Fusers%2F${actionner.id}/show`}>
          {actionner.fullName}
        </Link>
      ) : (
        ''
      )}
      )
    </>
  );
};
export const HistoryErrorWarningSuccessFormat = ({item, translate}) => {
  const {typeEvent} = item;
  const event = typeEvent.toLowerCase();

  return translate(`custom.components.history.${event}`);
};
