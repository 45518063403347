module.exports = {
  'Machine program installation': 'Program installation',
  'Machine program uninstallation': 'Program uninstallation',
  'Machine add to structure': 'Added to structure',
  'Machine remove from structure': 'Removed from structure',
  'Machine activation': 'Machine activation',
  'Machine deactivation': 'Machine deactivation',
  'Machine approval': 'Machine approval',
  'Machine disapproval': 'Machine disapproval',
  'Machine deletion': 'Machine deletion',
  'Program installation': 'Program installation',
  'Program uninstallation': 'Program uninstallation',
  'Program import': 'Program import',
  'Program import on machine': 'Program import',
  'Program export': 'Program export',
  'Program update from import': 'Program update',
  'Program update on machine': 'Program update',
  'Program tag added': 'Tag added',
  'Program tag removed': 'Tag removed',
  'Program approval': 'Program approval',
  'Program disapproval': 'Program disapproval',
  'Program creation': 'Program creation',
  'Tag creation': 'Tag creation',
  'Tag update': 'Tag update',
  'Tag added on program': 'Tag added',
  'Tag removed from program': 'Tag removed',
  'Structure add machine': 'Machine added',
  'Structure remove machine': 'Machine removed',
  'Structure creation': 'Structure creation',
  'Structure add user': 'User added',
  'Structure remove user': 'User removed',
  'Structure update': 'Structure update',
  'Structure deletion': 'Structure deletion',
  'User creation': 'User creation',
  'User deletion': 'User deletion',
  'User update': 'User update',
  'Connection as': 'logged in as',
  'on program': 'on program',
  'from program': 'from program',
  'on machine': 'on machine',
  'from machine': 'from machine',
  'on structure': 'on structure',
  'from structure': 'from structure',
  Unauthorized: 'Email ou mot de passe inconnus',
  'category: validators.program.category.not_blank':
    'Please select a machine type',
  'Failed to fetch': 'Server Error',
  'Not Found': 'Page not found.',
  more_count: '...and %count% more',
  custom: {
    action: {
      machines: {
        name: 'machine |||| machines',
        selected: 'selected',
        more_actions: 'For more actions, check a machine in the list.',
        connect: 'Connect',
        approve: 'Approve',
        disapprove: 'Disapprove',
      },
      programs: {
        name: 'program |||| programs',
        selected: 'selected',
        more_actions: 'For more actions, check a program in the list.',
      },
      tags: {
        name: 'tag |||| tags',
        selected: 'selected',
        more_actions: 'For more actions, check a tag in the list.',
      },
      structures: {
        name: 'structure |||| structures',
        selected: 'selected',
        more_actions: 'For more actions, check a structure in the list.',
      },
      users: {
        name: 'user |||| users',
        selected: 'selected',
        more_actions: 'For more actions, check a user in the list.',
      },
      inTotal: 'in total',
    },
    auth: {
      create_account: 'Create account',
      create_account_error: 'Unable to create account',
      disconnect: 'Disconnect',
      email: 'Email',
      password: 'Password',
      login: 'Eurofours login',
      sign_in: 'Log in',
      enter_security_code: 'Enter the security code to connect to this user',
      validate: 'Validate',
      errorCode:
        'The security code entered is invalid, please try again with a valid code',
      forgot_password: 'Forgot password',
      selectLanguage: 'Select the language',
      reset: 'Reset',
      user_created:
        'Your account has been created, activate it in your mailbox',
      account_activated: 'Your account has been activated successfully',
      account_desactivated: "The user's account is not activated",
    },
    buttons: {
      add: 'Add',
      deletebutton: 'Delete',
      disable: 'Disable',
      enable: 'Enable',
      exportbutton: 'Export',
      tags: 'Tags',
      importbutton: 'Import',
      stopImpersona: 'Stop connection',
      assign: {
        tags: {
          programs: {
            label: 'Add',
            description: 'to a program',
          },
        },
        machines: {
          structures: {
            label: 'Add',
            description: 'to a structure',
          },
          programs: {
            label: 'Install',
            description: 'a program',
          },
        },
        programs: {
          tags: {
            label: 'Add',
            description: 'a tag',
          },
          machines: {
            label: 'Install',
            description: 'on a machine',
          },
        },
        structures: {
          machines: {
            label: 'Add',
            description: 'a machine',
          },
          users: {
            label: 'Add',
            description: 'a user',
          },
        },
        users: {
          structures: {
            label: 'Add',
            description: 'to a structure',
          },
        },
      },
      unassign: {
        tags: {
          programs: {
            label: 'Remove',
            description: 'from a program',
          },
        },
        machines: {
          structures: {
            label: 'Remove',
            description: 'from a structure',
          },
          programs: {
            label: 'Uninstall',
            description: 'a program',
          },
        },
        programs: {
          tags: {
            label: 'Remove',
            description: 'a tag',
          },
          machines: {
            label: 'Uninstall',
            description: 'from a machine',
          },
        },
        structure: {
          machines: {
            label: 'Remove',
            description: 'a machine',
          },
          users: {
            label: 'Remove',
            description: 'a user',
          },
        },
        structures: {
          machines: {
            label: 'Remove',
            description: 'a machine',
          },
          users: {
            label: 'Remove',
            description: 'a user',
          },
        },
        users: {
          structures: {
            label: 'Remove',
            description: 'from a structure',
          },
          structure: {
            label: 'Remove',
            description: 'from a structure',
          },
        },
        user: {
          structures: {
            label: 'Remove',
            description: 'from a structure',
          },
        },
      },
      action: {
        classic: {
          programs: '',
          tags: 'the tag',
          machines: 'the machine',
          users: 'the user',
        },
        boolean: {
          programs: {
            on: 'Approve',
            off: 'Disapprove',
          },
          machines: {
            on: 'Enable',
            off: 'Disable',
            approve: 'Approve',
            disapprove: 'Disapprove',
          },
          users: {
            title: {on: 'Enable user', off: 'Disable user'},
            content: {
              on: 'Are you sure you want to enable this user ?',
              off: 'Are you sure you want to disable this user ?',
            },
            on: 'Enable',
            off: 'Disable',
          },
        },
        import: 'from...',
        export: 'to...',
      },
    },
    boolean: {
      enabled: 'enabled',
      disabled: 'disabled',
      enabling: 'Enable',
      disabling: 'Disable',
    },
    coming_soon: 'Coming soon !',
    creation: {
      email: 'Email',
      firstName: 'Firstname',
      language: {
        french: 'French',
        english: 'English',
      },
      temperatureUnitPreference: {
        label: 'Temperature Unit',
        celsius: 'Celsius',
        fahrenheit: 'Fahrenheit',
      },
      society: 'Society',
      city: 'City',
      country: 'Country',
      adress: 'Address',
      zipcode: 'Zipcode',
      languageLabel: 'Language',
      lastName: 'Lastname',
      password_again: 'Type again your password',
      countAllocatedMachine: 'Machines count',
    },
    commons: {
      here: 'here',
      name: 'Name',
      description: 'Description',
      adress: 'Address',
      phone: 'Phone',
      zipcode: 'Zipcode',
      temperatureUnitPreference: {
        label: 'Temperature unit preference',
        celsius: 'Celsius',
        fahrenheit: 'Fahrenheit',
      },
      city: 'City',
      country: 'Country',
      society: 'Society',
      assign: 'Assign',
      unassign: 'Unassign',
      category: 'Category',
      parent: 'Parent',
      userInCharge: 'User in charge',
      show: 'Show',
      edit: 'Edit',
      delete: 'Delete image',
      errors: {
        not_granted:
          'Sorry, you do not have the necessary rights to perform this action.',
      },
      tryagain: 'Try again',
      nbPrograms: 'Number programs',
      nbMachines: 'Number machines',
      createdat: 'Created at',
      updatedat: 'Updated at',
      authorizedUsers: 'Authorized users',
      history: 'Activity',
      noHistory: 'No Activity',
      charLeft: 'chars left',
      fullHistory: 'View full history',
      no_data: 'Data unavailable',
      defaults: 'Defaults',
      stats: 'Statistiques',
    },
    components: {
      appbar: {
        activity: 'Activity',
        profile: 'Profile',
        programsImages: 'Programs images',
        machinesImages: 'Machines images',
        manageImages: 'Manage your images',
        access: 'Access control',
        roles: {
          superAdmin: 'Super Admin',
          eurofoursTechnician: 'Eurofours Technician',
          clientAdmin: 'Administrator',
          user: 'User',
        },
      },
      createTechnician: {
        title: 'Create a technician',
      },
      gauth: {
        '2fa': 'Two factor authentication',
      },
      filter: {
        reset: 'Reset filters',
        to_approve: {
          programs:
            '%{smart_count} program to approve |||| %{smart_count} programs to approve',
        },
      },
      home: {
        welcome: 'Welcome',
        securityCode: 'Security code',
        resume: {
          title: 'Account resume',
          programsApproved: 'approved program |||| approved programs',
          machines: 'connected machine |||| connected machines',
          machinesToBeEnabled:
            'machine waiting for activation |||| machines waiting for activation',
          structureWithMachines: 'equiped structure |||| equiped structures',
          usersCount: 'user |||| users',
        },
        basics: {
          title: 'Basics',
          firstBloc: {
            whatIsThis:
              'eTools is a remote management service which let the user control directly his machine park. eTools is a measuring, analysis and decision helper tool to optimize the production for one or more point of sale.',
            userCan: 'Using the interface, the user can :',
            managePrgMach: 'Manage programs on his machines.',
            organise:
              'Organize his machines by structure : sector, country, point of sale or any other choice criterias.',
            manageUsers:
              'Manage his delegates users and attribute them the responsability of one or many structures.',
            see: 'Display in real time machines states and activity.',
            alerts: 'Receive alerts when failure appear.',
            extractDatas:
              'Extract use datas from machines park to analyze them.',
            safety: 'All in secure environment.',
          },
          secondBloc: {
            programs:
              'Let your creativity do the job to create your best programs ! Just a blink of the eye and they are installed on your machines you wanted. The only limits are yours !',
            tags:
              'Believe, they will be your best friends soon. They allow you to qualify your programs to find it easier later or group them by thematic.',
            machines:
              'Did your machines built by Eurofours® ? It is a good thing, you will be allowed to add them to eTools. For others, we cannot do anything. Install your programs, optimize your production seeing the use history or check the potential working errors.',
            structures:
              'They are representing the different production or sales sites which you have at your disposal. Organize them to match to your tastes and attribute them all the equipements Eurofours® power.',
            users:
              'Since you did the hardware Eurofours® choice your production increase a lot and with this, your structures count ? Delegate your tasks creating new users and customize their rights and their features.',
          },
        },
      },
      image_uploader: {
        label: 'Select a picture',
        previewLabel: 'Preview',
        buttonLabel: 'Set the preview',
        submitLabel: 'Save picture',
        sending: 'Uploading the picture, wait',
        success: 'The picture has been uploaded successfuly',
        error: 'An error occured, try again later',
        images_eurofours: 'eTools Gallery',
        images_upload: 'Load new picture',
        images_bibliotheque: 'My Gallery',
        sizeLabel:
          'The uploaded image is too heavy, please retry the operation with an image smaller than 5 MB.',
      },
      machine: {
        type: 'Type',
        serial: 'Serial number',
        format: 'Format',
        none: 'No machines availables for this structure',
        history: 'History',
        usage: 'Usage',
        noUsage: 'No Usage',
        usage_types: {
          PowerOn: 'Power On',
          PreHeatingStart: 'PreHeating Start',
          WashEnd: {
            ShortWash: 'Stopping short wash',
            LongWash: 'Stopping long wash',
            StopOdor: 'Stopping stop odor wash',
          },
          WashStart: {
            ShortWash: 'Short Wash',
            LongWash: 'Long Wash',
            StopOdor: 'Stop Odor Wash',
          },
          PowerOff: 'Shut down',
          AutoPowerOn: 'Automatic power on',
          AutoPowerOff: 'Automatic power off',
          PreheatStart: 'Start preheating',
          PreheatEnd: 'End preheating',
          DiagnosticStart: 'Diagnostic start',
          DiagnosticEnd: 'Diagnostic end',
          SleepStart: 'Sleeping mode start',
          SleepEnd: 'Sleeping mode end',
          AutoSleepStart: 'Automatic sleeping mode',
          ProgramStart: 'Start cooking',
          ProgramEnd: 'Stop cooking',
          ManualStart: 'Start manuel cooking',
          ManualEnd: 'Stop manual cooking',
        },
      },
      manage_rights: {
        title: 'Manage rights',
      },
      menu: {
        structures: 'Structures',
        machineType: 'Machine type',
        machines: 'Machines',
        programs: 'Programs',
        users: 'Users',
        tags: 'Tags',
        home: 'Home',
        eurofoursTechnicians: 'Eurofours Technicians',
        clients: 'Clients',
        softwareUpdates: 'Software updates',
      },
      passwords: {
        label: 'Password',
        change: 'Update password',
        lastPassword: 'Last password',
        newPassword: 'New password',
        newPassword2: 'Re-enter your new password',
        mustMatch: 'Error, passwords are differents',
        successChanged: 'Your password has been changed successfully',
        errorChanged:
          "Your password couldn't be updated, please try again later",
        resetInfo:
          'Validation of your reset token for your password in progress',
        resetError: 'Invalid reset token',
        submit: 'Submit new password',
        submitRequest: 'Apply a reset password request',
        successReset: 'An email has been sent to update your password',
        reset: 'Apply a reset password request',
        badPassword: 'Bad password',
        error: "Your password couldn't be updated, please try again later",
      },
      profile: {
        title: 'Profile',
        showProfileButton: 'Show your profile',
        editProfileButton: 'Edit your profile',
        gauthis: 'Google authenticator is',
        onaccount: 'on your account',
        itsrightmoment: "It's the right moment to",
        activate: 'enable it',
        qrcodefield: 'Write the code here',
        qrcode:
          'Please scan the QR code to retrieve your security key. It will be asked every time you log in.',
        disable: 'You can disable it by clicking',

        GAuthExplanation:
          'Two factor authentication is provided by Google Authentication.',
        GAuthDownload: 'You can download it on',
        history: {
          view: "View your own account's history",
          backProfile: 'Back to your profile',
          title: 'Your profile history',
          accessPlus: {
            title: 'Access+ history',
            connectionAs: '%{eurofours_user} has logged in as %{client_user}',
          },
        },
        structures: {
          noResults: 'No associated structures found.',
        },
        unfoundables: {
          firstname: 'Unfoundable firstname',
          lastname: 'Unfoundable lastname',
        },
        notificationsEmail: {
          main: 'Email notification',
          problems: {
            title: 'Problems',
            enabledDescription:
              "You'll be notified when a problem will be encountered on one of your machines.",
            disabledDescription:
              "You won't be notified when a problem will be encountered on one of your machines.",
          },
          programEdit: {
            title: 'Edit programs',
            enabledDescription:
              "You'll be notified when a program will be edited from one of your machines.",
            disabledDescription:
              "You won't be notified when a program will be edited from one of your machines.",
          },
          programCreate: {
            title: 'Program creation',
            enabledDescription:
              "You'll be notified when a program will be created from one of your machines.",
            disabledDescription:
              "You won't be notified when a program will be created from one of your machines.",
          },
        },
        assistance: {
          main: 'Support+',
          enabled: {
            title: 'Enabled',
            description: 'You share your access with the Eurofours Support',
          },
          disabled: {
            title: 'Disabled',
            description:
              "You're not sharing your access with the Eurofours Support",
          },
        },
      },
      programs: {
        assigned: 'programs assigned',
        none: 'No programs',
        create: {
          title: 'Create program',
          choiceMachine: 'Choose the machine',
          choiceTypeMachine:
            'For which machine type would you create new program ?',
          identify: 'Identify',
          name: 'Give a name to your program',
          settings: 'Parameters',
          fillsettings: 'Fill differents parameters to your program',
          save: 'Register',
          save_desc:
            'Congratulations ! Your program has been created. It is waiting for approval.',
          save_ask: 'Do you want to approve it ?',
          save_approve: 'All users can access an approved program.',
          save_disapprove:
            'Only granted users can access it. You can approve it later.',
          cantApprove: 'You dont have the privilege to approve a program.',
        },
      },
      selectUser: {
        title: 'Select a user to access to his account',
      },
      selectEurofoursTechnician: {
        title: 'Select an Eurofours technician to access to his account',
      },
      selectClientAdmin: {
        title: 'Select a client admin to access to his account',
      },
      structures: {
        assigned: 'structures assigned',
        none: 'No structure',
        create: {
          title: 'Create a structure',
        },
        edit: {
          title: 'Edit a structure',
        },
      },
      software_updates: {
        create: {
          title: 'Software update create',
          semverRegexValidation: 'Version should be valid',
          filePlaceholder: 'Click for select the file, or drop it',
        },
        edit: {
          title: 'Software update edit',
          success: 'Update success',
        },
      },
      tags: {
        assigned: 'assigned tags',
        none: 'No tags',
        noDescription: 'No description provided',
        create: {
          title: 'Create a tag',
        },
      },
      users: {
        none: 'No users',
        resetPassword: 'Reset your password',
        resetPasswordSuccess: 'Password has been reset successfully',
        resetPasswordFailed: "Password couldn't be reset",
        noPhone: 'No phone number provided',
        noDescription: 'No description provided',
        noAddress: 'No address provided',
        googleAuthenticatorConfirmed: 'Google authenticator enabled',
        emailVerified: 'Email verified',
        create: {
          title: 'Add delegate',
        },
      },
      history: {
        assignedto: {
          machine: 'installed on',
          default: 'added to',
        },
        unassignedfrom: {
          machine: 'uninstalled from',
          default: 'removed from',
        },
        user: {
          type: 'User',
          create: 'Creation of the user',
          delete: 'Deletion of user %{name}',
          update: {
            default: 'Update user',
            imageBase64: 'Update picture of user',
            email: 'Update email user',
            emailVerified: 'Verify email user',
            password: 'Update password user',
            firstName: 'Update first name user',
            lastName: 'Update last name user',
            address: 'Update address user',
            mobilePhone: 'Update mobile phone user',
            fixedPhone: 'Update fixed-line user',
            roles: 'Update roles user',
            googleAuthenticatorSecret: 'Configure GAuth user',
            googleAuthenticatorConfirmed: '%{isActive} GAuth user',
          },
        },
        machine: {
          type: 'Machine',
          create: 'Creation of the machine',
          delete: 'Deletion of machine %{name}',
          update: {
            default: 'Update machine',
            avatar: 'Update picture of machine',
            name: 'Update name of machine',
            description: 'Update description of machine',
            pin: 'Update pin of machine',
            active: '%{isActive} machine',
          },
        },
        program: {
          type: 'Program',
          create: 'Creation of the program',
          delete: 'Deletion of program %{name}',
          update: {
            default: 'Update program identity',
            avatar: 'Update picture of program',
            phase_groups: 'Update parameters of program',
            active: '%{isActive} program',
          },
        },
        tag: {
          type: 'Tag',
          create: 'Creation of the tag',
          delete: 'Deletion of tag %{name}',
          update: {
            default: 'Update tag',
            name: 'Update name of tag',
            description: 'Update description of tag',
          },
        },
        structure: {
          type: 'Structure',
          create: 'Creation of the structure',
          delete: 'Deletion of structure %{name}',
          update: {
            default: 'Update structure',
            name: 'Update name of structure',
          },
        },
        error: 'An error occured',
        warning: 'A warning occured',
        success: 'An operation was successfull',
      },
      roles: {
        create: 'Create',
        edit: 'Edit',
        delete: 'Delete',
        importExport: 'Import & export',
        programs: {
          main: 'The user can see all approved programs',
          create: 'The user can create a new program',
          edit: 'The user can edit an existing program',
          delete: 'The user can delete a program',
          importExport: 'The user can import/export some programs',
          accessUnapproved: {
            title: 'Access to unapproved programs',
            description:
              'The user can access all approved/unapproved programs of their structures',
          },
          approve: {
            title: 'Approve programs',
            description: 'The user can approve and unapprove a program',
          },
        },
        machines: {
          main: 'The user can see all approved machines of their structures',
          create: 'The user can create a new machine (1)',
          create_help:
            'Other required rights for Machines/Create: Structures, Programs/Create',
          edit: 'The user can edit an existing machine',
          delete: 'The user can delete a program',
          accessWithoutStructure: {
            title: 'Access to machines without structure',
            description: 'The user can see machines without structure',
          },
          installUninstall: {
            title: 'Install & Uninstall programs',
            description:
              'The user can install and uninstall programs on a machine',
          },
          approve: {
            title: 'Enable machines',
            description: 'The user can enable and disable a machine',
          },
        },
        tags: {
          main: 'The user can see the tags of their structures',
          create: 'The user can create a new tag',
          edit: 'The user can edit an existing tag',
          delete: 'The user can delete a tag',
        },
        structures: {
          main: 'The user can see their structures',
          create: 'The user can create a new structure',
          edit: 'The user can edit an existing structure',
          delete: 'The user can delete a structure',
          accessAllStructure: {
            title: 'Access to all structures',
            description: 'The user can see all existing structures',
          },
          addRemove: {
            title: 'Add and remove machines',
            description:
              'The user can add a machine to, and remove a machine from a structure',
          },
        },
      },
      show: {
        see_more: 'See more',
      },
    },
    errors: {
      E01: 'Oven probe fail',
      E02: 'Board sensor fail',
      E03: 'Security thermostat',
      E04: 'Motor thermistor',
      E05: 'Date and time warning',
      E06: 'Board high temp',
      E07: 'Board high temp : Shutting down',
      E08: 'Power failure',
      not_resolved: 'Not resolved',
    },
    title: {
      tags: 'a tag |||| %{smart_count} tags',
      machines: 'a machine |||| %{smart_count} machines',
      programs: 'a program |||| %{smart_count} programs',
      structures: 'a structure |||| %{smart_count} structures',
      users: 'a user |||| %{smart_count} users',
      images_machines: 'Machines images',
      images_programs: 'Programs images',
      image_update: 'Update machine image %{code}',
      assign: {
        tags: {
          programs: 'Add %{subject}',
        },
        machines: {
          structures: 'Add %{subject}',
          programs: 'Install on %{subject}',
        },
        programs: {
          tags: 'Add to %{subject}',
          machines: 'Install %{subject}',
        },
        structures: {
          machines: 'Add to %{subject}',
          users: 'Add to %{subject}',
        },
        users: {
          structures: 'Add %{subject}',
        },
      },
      unassign: {
        tags: {
          programs: 'Remove %{subject}',
        },
        machines: {
          structures: 'Remove %{subject}',
          programs: 'Uninstall from %{subject}',
        },
        programs: {
          tags: 'Remove %{subject}',
          machines: 'Uninstall %{subject}',
        },
        structures: {
          machines: 'Remove from %{subject}',
          users: 'Remove from %{subject}',
        },
        users: {
          structures: 'Remove %{subject}',
        },
      },
    },
    images: {
      upload: {
        success: 'Image uploaded with success !',
        sending: 'Sending in progress',
        failure: 'An error occured during upload',
      },
      manage: 'View images',
      add: 'Add image',
      delete: {
        title: 'Delete image',
        content: 'Are you sure you want to delete this image ?',
      },
      machine_code: 'Logo id (ex: 0)',
      machine_description: 'Machine description',
    },
    input: {
      file: {},
      image: {},
      references: {},
    },
    message: {
      disable: {
        activate: 'Activate',
        disable: 'Disable',
        title: '%{action}',
        content: '%{action} one user |||| %{action} %{smart_count} users',
      },
      bulk_delete_title: {
        tags: 'Remove one tag |||| Remove %{smart_count} tags',
        machines: 'Remove one machine |||| Remove %{smart_count} machines',
        programs: 'Remove one program |||| Remove %{smart_count} programs',
        users: 'Remove one user |||| Remove %{smart_count} users',
        structures:
          'Remove one structure |||| Remove %{smart_count} structures',
      },
      update_property: {
        tags: {},
        machines: {
          approved: {
            on: 'Connect %{subject}',
          },
          active: {
            on: 'Activate %{subject}',
            off: 'Desactivate %{subject}',
          },
        },
        programs: {
          approved: {
            on: 'Approve %{subject}',
            off: 'Disapprove %{subject}',
          },
        },
        users: {
          active: {
            on: 'Activate %{subject}',
            off: 'Desactivate %{subject}',
          },
        },
        structures: {},
        confirm: 'Confirm ?',
      },
    },
    navigation: {},
    notification: {
      email_updated: 'Email updated, please login again to proceed.',
      account_updated: 'Account has been updated',
    },
    page: {},
    validation: {
      samepassword: 'Error, passwords are differents',
      pin: 'Must contains 4 numbers',
    },
  },
  resources: {
    program_machines: {
      fields: {
        position: 'Position',
        errors: 'Defaults',
      },
    },
    machines: {
      edit: 'Edit a machine',
      create: 'Create a machine',
      fields: {
        name: 'Name',
        category: 'Category',
        structure: 'Structure',
        serialNumber: 'Serial number',
        specifications: {
          capacity: 'Capacity',
          energy: 'Energy',
        },
        format: 'Format',
        active: 'Active',
        approved: 'Approved',
      },
    },
    structures: {
      edit: 'Edit a structure',
      create: 'Create a structure',
      fields: {
        name: 'Name',
        machines: 'Machines',
        users: 'Users',
      },
    },
    programs: {
      edit: 'Edit a program',
      create: 'Create a program',
      name: 'program',
      installed_on: 'machines installed',
      fields: {
        name: 'Name',
        category: 'Category',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        nbMachines: 'Machines',
        tags: 'Tags',
        machines: 'Machines',
        approved: 'Approve',
        programMachines: 'Machines',
      },
      errors: {
        required: 'This value is required',
        limits: 'This value must be between %{min} and %{max}',
      },
      confirmDuration: {
        title: 'The duration of the program will be reduced!',
        content:
          'By changing this value, the total program duration will be reduced and other phases will be deleted. Are you sure you want to validate this change?',
      },
    },
    users: {
      edit: 'Edit a user',
      create: 'Create a user',
      name: 'users',
      fields: {
        email: 'Email',
        password: 'Password',
        lastName: 'Last name',
        firstName: 'First name',
        society: 'Society',
        tags: 'Tags',
        nbStructures: 'Structures',
        googleAuthenticatorConfirmed: 'Google authenticator enabled',
        emailVerified: 'Email verified',
        address: 'Address',
        phone: 'Phone',
        street: 'Street',
        city: 'City',
        zipcode: 'Zipcode',
        country: 'Country',
        countryCode: 'Language',
        temperatureUnitPreference: 'Temperature Unit Preference',
      },
    },
    tags: {
      edit: 'Edit a tag',
      create: 'Create a tag',
      name: 'tags',
      fields: {
        name: 'Name',
        createdAt: 'Created at',
        programs: 'Programs',
      },
    },
    software_updates: {
      fields: {
        addRevoked: 'Revoked',
        isAllowedBeta: 'Beta',
        version: 'Version',
        beta: 'Beta',
        revoked: 'Revoked',
        description: 'Description',
        checksum: 'Checksum',
        file: 'File',
        countDownloaded: 'Count downloaded',
      },
    },
  },
};
