import React, {Component, Fragment} from 'react';
import {FormTab} from 'react-admin';
import Title from '../Common/Header';
import {Edit} from '../Common/Admin';
import EditInfo from './view/edit/editInfo';
import EditParameters from './view/edit/editParameters';
import {hasRoles} from '../../utils/auth';
import CustomTabbedForm from './Form/ProgramTabbedForm';

class ProgramEdit extends Component {
  render() {
    if (!hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_PROGRAM_EDITOR'])) {
      window.location.replace('/#/not-granted');
    }

    return (
      <Edit
        undoable={false}
        title={<Title redirect="show" {...this.props} />}
        actions={<Fragment />}
        {...this.props}>
        <CustomTabbedForm submitOnEnter={false} redirect="show">
          <FormTab label="custom.components.programs.create.identify">
            <EditInfo />
          </FormTab>
          <FormTab label="custom.components.programs.create.settings">
            <EditParameters />
          </FormTab>
        </CustomTabbedForm>
      </Edit>
    );
  }
}

export default ProgramEdit;
