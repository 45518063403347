import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Link} from 'react-router-dom';
import {
  EditButton,
  SimpleShowLayout,
  TextField,
  translate,
  resolveRedirectTo,
  FunctionField,
} from 'react-admin';
import {Show} from '../Common/Admin';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  SvgIcon,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import {DeleteWithConfirmButton} from 'react-admin';
import {Restore, ReportProblem, ShowChart} from '@material-ui/icons';
import {
  getOneMachine,
  getMachineErrors,
  getMachineActivities,
} from './store/action';
import {machineUpdateProgramPosition} from './store/action';
import {ErrorGenerator} from '../Error';
import TabsCommon from '../Common/Tabs';
import MachineActions from '../ActionsCards/MachineActions';
import PartialHistory from '../History/Partial';
import {TopBarList, Breadcrumb} from '../TopBar';
import Title from '../Common/Header';
import './show.css';
import Loading from '../Loading';
import GridList from '../Common/GridList';
import UsageListItem from '../Usage';
import {hasRoles} from '../../utils/auth';

class InputPositionComponent extends Component {
  handleChange = event => {
    this.props.machineUpdateProgramPosition({
      id: this.props.item,
      position: event.target.value,
    });
  };

  render() {
    const {position} = this.props;
    return (
      <input
        value={position}
        type="number"
        className="sort-number"
        onChange={this.handleChange}
      />
    );
  }
}

const InputPosition = connect(undefined, {machineUpdateProgramPosition})(
  InputPositionComponent,
);

function formatDate(date) {
  date = new Date(date.split('+')[0]);
  let day = `${date.getDate()}`.padStart(2, '0');
  let month = `${date.getMonth() + 1}`.padStart(2, '0'); // Notez l'ajout de 1 ici
  let year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

function formatStructures(structure, render) {
  return structure.parent
    ? `${formatStructures(structure.parent, ` > ${structure.name + render}`)}`
    : structure.name + render;
}

const ProgramsMachine = translate(({translate, machine}) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>
          {translate('resources.program_machines.fields.position')}
        </TableCell>
        <TableCell>{translate('resources.programs.fields.name')}</TableCell>
        <TableCell>
          {translate('resources.programs.fields.createdAt')}
        </TableCell>
        <TableCell>
          {translate('resources.programs.fields.updatedAt')}
        </TableCell>
        <TableCell>{translate('resources.programs.fields.tags')}</TableCell>
        <TableCell>{translate('resources.programs.fields.machines')}</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {!machine ? (
        <LinearProgress />
      ) : (
        machine.programMachines.map((programMachine, index) => {
          const {position, program} = programMachine,
            id = programMachine['@id'];
          return (
            <TableRow
              key={index}
              className={
                6 === index % 6 && machine.programMachines[index + 1]
                  ? 'program-separator'
                  : ''
              }>
              <TableCell>
                <InputPosition position={position} item={id} />
              </TableCell>
              <TableCell className="name-program">
                <Link
                  to={`/programs/${encodeURIComponent(program['@id'])}/show`}>
                  {program.name}
                </Link>
              </TableCell>
              <TableCell>{formatDate(program.createdAt)}</TableCell>
              <TableCell>
                {program.updatedAt === program.createdAt
                  ? '-'
                  : formatDate(program.updatedAt)}
              </TableCell>
              <TableCell>
                {program.tags.length > 1 ? (
                  program.tags.length
                ) : program.tags.length ? (
                  <Link
                    to={resolveRedirectTo(
                      'show',
                      '/tags',
                      program.tags[0]['@id'],
                    )}>
                    {program.tags[0].name}
                  </Link>
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell>{program.nbMachines}</TableCell>
            </TableRow>
          );
        })
      )}
    </TableBody>
  </Table>
));

const MachineHistory = ({machineActivities, id}) => (
  <Grid item>
    <Grid container direction="column" className="tag-content" wrap="nowrap">
      <Grid item>
        <PartialHistory title id={id} />
      </Grid>
      {machineActivities && (
        <Grid item>
          <GridList
            data={machineActivities}
            title="custom.components.machine.usage"
            noDataDesc="custom.components.machine.noUsage">
            <UsageListItem />
          </GridList>
        </Grid>
      )}
    </Grid>
  </Grid>
);

const MachineInformations = translate(({translate, machine, ...rest}) => {
  if (!machine) {
    return <Loading />;
  }

  if (!hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_MACHINE_ACCESS'])) {
    window.location.replace('/#/not-granted');
  }

  const {
    category,
    serialNumber,
    specifications,
    structure,
    name,
    avatar,
  } = machine;

  return (
    <Grid container>
      <Grid item xs={9} className="show-info">
        <Grid container direction="column">
          <Grid item>
            <Grid container className="show-info-content">
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={5} className="informations informations-left">
                    <Typography component="span">
                      {translate('custom.components.machine.type')}
                    </Typography>
                  </Grid>
                  <Grid item xs={7} className="informations">
                    {category != null && category.name != null ? (
                      <Typography component="span">{category.name}</Typography>
                    ) : (
                      <span></span>
                    )}
                  </Grid>
                  <Grid item xs={5} className="informations informations-left">
                    <Typography component="span">
                      {translate('custom.components.machine.serial')}
                    </Typography>
                  </Grid>
                  <Grid item xs={7} className="informations">
                    <Typography component="span">{serialNumber}</Typography>
                  </Grid>
                  {Object.keys({}).map(
                    (itemKey, index) =>
                      specifications[itemKey] && (
                        <Fragment key={index}>
                          <Grid
                            item
                            xs={5}
                            className="informations informations-left">
                            <Typography component="span">{itemKey}</Typography>
                          </Grid>
                          <Grid item xs={7} className="informations">
                            {Array.isArray(specifications[itemKey]) ? (
                              <Fragment>
                                {specifications[itemKey].map((item, index) => (
                                  <Typography key={index} component="span">
                                    {item}
                                  </Typography>
                                ))}
                              </Fragment>
                            ) : (
                              <Typography component="span">
                                {specifications[itemKey]}
                              </Typography>
                            )}
                          </Grid>
                        </Fragment>
                      ),
                  )}
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <SimpleShowLayout {...rest}>
                  <TextField source="name" />
                  <FunctionField
                    source="structure"
                    render={() =>
                      structure
                        ? formatStructures(structure, '')
                        : translate('custom.components.structures.none')
                    }
                  />
                  <TextField source="description" />
                  <TextField source="pin" />
                </SimpleShowLayout>
                {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_MACHINE_EDITOR']) && (
                  <Grid item className="align-right">
                    <EditButton label="custom.commons.edit" {...rest} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <img
          alt={`${name}-avatar`}
          className="img-fluid"
          src={
            avatar
              ? `${process.env.REACT_APP_API_ENTRYPOINT}/images/machines/${avatar}`
              : `${
                  category != null && category.name != null
                    ? process.env.REACT_APP_API_ENTRYPOINT +
                      '/images/furnaces/' +
                      category.name.toLowerCase() +
                      '.jpg'
                    : ''
                }`
          }
        />
      </Grid>
    </Grid>
  );
});

class MachineShow extends Component {
  componentDidMount() {
    const {
      id,
      getOneMachine,
      getMachineErrors,
      getMachineActivities,
    } = this.props;
    const machineId = id.split('/')[2];
    getOneMachine(machineId);
    getMachineErrors(machineId);
    getMachineActivities(machineId);
  }

  componentDidUpdate = prevProps => {
    const {
      id,
      getOneMachine,
      getMachineErrors,
      getMachineActivities,
    } = this.props;
    if (prevProps.id !== id) {
      const machineId = id.split('/')[2];
      getOneMachine(machineId);
      getMachineErrors(machineId);
      getMachineActivities(machineId);
    }
  };

  sanitizeProps = ({
    getOneMachine,
    getMachineErrors,
    getMachineActivities,
    ...rest
  }) => rest;

  render() {
    const {machine, machineErrors, translate, ...rest} = this.props;

    return (
      <>
        <TopBarList>
          <Breadcrumb label={machine ? machine.name : ''} />
          {machine && (
            <div>
              {hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_MACHINE_DELETE']) && (
                <DeleteWithConfirmButton
                  record={{id: machine['@id']}}
                  resource="machines"
                  basePath="/machines"
                />
              )}
            </div>
          )}
        </TopBarList>
        <Show
          title={<Title redirect="list" {...this.sanitizeProps(rest)} />}
          actions={<MachineActions displayTotal={false} />}
          {...this.sanitizeProps(rest)}>
          <SimpleShowLayout>
            <MachineInformations machine={machine} />
            <TabsCommon
              items={[
                {
                  icon: (
                    <SvgIcon viewBox="-3 -1 21 21">
                      <path d="M12.56,0H1.79A1.79,1.79,0,0,0,0,1.79V16.15a1.79,1.79,0,0,0,1.79,1.79H12.56a1.79,1.79,0,0,0,1.79-1.79V1.79A1.79,1.79,0,0,0,12.56,0ZM1.79,1.79H6.28V9L4,7.62,1.79,9Z" />
                    </SvgIcon>
                  ),
                  content: <ProgramsMachine machine={machine} />,
                  label: 'custom.components.menu.programs',
                },
                {
                  icon: <Restore />,
                  content: <MachineHistory {...this.props} />,
                  label: 'custom.components.machine.history',
                },
                {
                  icon: <ReportProblem />,
                  content: (
                    <ErrorGenerator
                      items={!machineErrors ? undefined : machineErrors}
                    />
                  ),
                  label: 'custom.commons.defaults',
                },
                {
                  icon: <ShowChart />,
                  content: <h3>{translate('custom.coming_soon')}</h3>,
                  label: 'custom.commons.stats',
                },
              ]}
            />
          </SimpleShowLayout>
        </Show>
      </>
    );
  }
}

const mapStateToProps = ({machineReducer}) => ({
  machine: machineReducer.machine,
  machineErrors: machineReducer.machineErrors,
  machineActivities: machineReducer.machineActivities,
});

export default compose(
  translate,
  connect(mapStateToProps, {
    getOneMachine,
    getMachineErrors,
    getMachineActivities,
  }),
)(MachineShow);
