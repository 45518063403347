import React, {Fragment} from 'react';
import {translate, Link} from 'react-admin';
import {linkToRecord} from 'ra-core';
import {Typography, Grid} from '@material-ui/core';

export default translate(({item: {type, date, program, mode}, translate}) => {
  const dateObject = new Date(date.date || date);

  return (
    <Grid container wrap="nowrap">
      <Grid item xs={3}>
        <Typography style={{fontWeight: 600}}>
          {dateObject.toLocaleString()}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography style={{color: '#606060', fontWeight: 500}}>
          {translate(
            `custom.components.machine.usage_types.${type}${
              mode ? `.${mode}` : ''
            }`,
            {
              _: type,
            },
          )}
          {program && (
            <Fragment>
              {' ('}
              <Link to={linkToRecord('/programs', program['@id'], 'show')}>
                {program.name}
              </Link>
              )
            </Fragment>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
});
