import React, {Fragment} from 'react';
import {
  SimpleForm,
  TextInput,
  LongTextInput,
  required,
  maxLength,
} from 'react-admin';
import {Edit} from '../Common/Admin';
import Title from '../Common/Header';
import {hasRoles} from '../../utils/auth';

const validateName = [required(), maxLength(255)];
const validateDescription = [maxLength(255)];

export default props => {
  if (!hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_TAG_EDITOR'])) {
    window.location.replace('/#/not-granted');
  }

  return (
    <Edit
      title={<Title redirect="show" {...props} />}
      undoable={false}
      actions={<Fragment />}
      {...props}>
      <SimpleForm redirect="show">
        <TextInput source="name" validate={validateName} />
        <LongTextInput source="description" validate={validateDescription} />
      </SimpleForm>
    </Edit>
  );
};
