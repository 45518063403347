import React, {Fragment} from 'react';
import {TextInput, ReferenceInput, SimpleForm, required} from 'react-admin';
import StructureInput from '../Input/StructureInput';
import Title from '../Common/Header';
import qs from 'qs';
import {Edit} from '../Common/Admin';
import {hasRoles} from '../../utils/auth';
import {connect} from 'react-redux';

const isRequired = [required()];

const StructureEdit = props => {
  if (!hasRoles(['ROLE_CLIENT_ADMIN', 'ROLE_STRUCTURE_EDITOR'])) {
    window.location.replace('/#/not-granted');
  }

  const {list, ...rest} = props;

  const filter = list.filter || {};
  const sort = list.sort || {
    field: 'id',
    order: 'DESC',
  };
  const url = `/${props.resource}?${qs.stringify({
    filter: JSON.stringify(filter),
    order: {
      [sort.field]: sort.order,
    },
    page: list.page || 1,
    perPage: list.perPage || 10,
  })}`;

  return (
    <Edit
      title={<Title redirect="show" {...rest} />}
      actions={<Fragment />}
      undoable={false}
      {...rest}
      redirect={false}>
      <SimpleForm redirect={url}>
        <TextInput source="name" validate={isRequired} />
        <ReferenceInput
          source="parent"
          reference="structures"
          label="Parent"
          perPage={500}
          sort={{field: 'name', order: 'ASC'}}>
          <StructureInput forbiddenIds={[props.id]} />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

const mapStateToProps = state => ({
  list: state.admin.resources.structures.list.params,
});

export default connect(mapStateToProps, {})(StructureEdit);
